import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, getCategories } from "../../Redux/Actions/Action";
import axios from "axios";
const baseURL = "https://mitserver.app:1515";
const imgURL = "https://poly-lite.com/images/product-catalog/";

function ProductAdd() {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.Mainreducer.categories);
	const [imageFile, setImageFile] = useState(null);
	const [previewImageUrl, setPreviewImageUrl] = useState(null);

	const [row, setRow] = useState([]);
	// const [categories, setCategories] = useState([]);

	var linkImage = imgURL + row.image;

	// useEffect(() => {
	//     var row = location.state;
	//     console.log("ROW", row);
	//     setRow(row);
	// }, [location]);

	// useEffect(() => {
	//     getCategories();
	// }, []);

	// async function getCategories() {
	//     const response = await axios.post(baseURL + "/getLists", {
	//         list: 'categories'
	//     })
	//     var resp = response.data;
	//     var result = resp.result;
	//     var data = result.data;
	//     console.log("CATEGORIES", data);
	//     setCategories(data);
	// }

	async function saveProductOLD() {
		const response = await axios.post(baseURL + "/actions", {
			action: "add",
			table: "products",
			data: row,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP", result);
		dispatch(getProducts);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
		history.push({
			pathname: "/product-list",
		});
	}
	async function saveProduct() {
		const formData = new FormData();
		formData.append("action", "add");
		formData.append("table", "products");
		formData.append("data", JSON.stringify(row));
		if (imageFile) {
			formData.append("file", imageFile);
		}

		const response = await axios.post(baseURL + "/actions", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const result = response.data.result;
		dispatch(getProducts);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
		history.push("/product-list");
	}

	function nameChange(e) {
		setRow({
			...row,
			name: e.target.value,
		});
	}

	function descriptionChange(e) {
		setRow({
			...row,
			description: e.target.value,
		});
	}

	function priceChange(e) {
		setRow({
			...row,
			price: e.target.value,
		});
	}

	function codeChange(e) {
		setRow({
			...row,
			code: e.target.value,
		});
	}

	const categoryChange = (e) => {
		console.log("VALUE", e.target.value);
		setRow({
			...row,
			category: e.target.value,
		});
	};

	function stockChange(e) {
		setRow({
			...row,
			stock: e.target.value,
		});
	}

	function unitChange(e) {
		setRow({
			...row,
			unit: e.target.value,
		});
	}

	function weightChange(e) {
		setRow({
			...row,
			weight: e.target.value,
		});
	}

	function statusChange(e) {
		setRow({
			...row,
			status: e.target.value,
		});
	}

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		console.log("FILE", file);
		setImageFile(file); // Actualizar el estado imageFile

		// Crear una URL temporal para la imagen
		const imageUrl = URL.createObjectURL(file);

		// Actualizar el estado previewImageUrl con la URL temporal
		setPreviewImageUrl(imageUrl);
	};

	return (
		<div className="container-xxl">
			<div className="row align-items-center">
				<div className="border-0 mb-4">
					<div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
						<h3 className="fw-bold mb-0">Product Add</h3>
						<button type="submit" className="btn btn-primary btn-set-task w-sm-100 text-uppercase px-5" onClick={saveProduct}>
							Save
						</button>
					</div>
				</div>
			</div>
			<div className="row g-3">
				<div className="col-xl-4 col-lg-4">
					<div className="sticky-lg-top">
						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Pricing Info</h6>
							</div>
							<div className="card-body">
								<div className="row g-3 align-items-center">
									<div className="col-md-12">
										<label className="form-label">Product Price</label>
										<input type="text" className="form-control" value={row.price} onChange={priceChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Inventory Info</h6>
							</div>
							<div className="card-body">
								<div className="row g-3 align-items-center">
									<div className="col-md-12">
										<label className="form-label">SKU</label>
										<input type="text" className="form-control" value={row.code} onChange={codeChange} />
									</div>
									<div className="col-md-12">
										<label className="form-label">Total Stock Quantity</label>
										<input type="text" className="form-control" value={row.stock} onChange={stockChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Visibility Status</h6>
							</div>
							<div className="card-body" onChange={statusChange}>
								<div className="form-check">
									<input className="form-check-input" checked={row.status === "Enable"} type="radio" name="status" value="Enable" />
									<label className="form-check-label">Enable</label>
								</div>
								<div className="form-check">
									<input
										className="form-check-input"
										checked={row.status === "Disable"}
										type="radio"
										name="status"
										value="Disable"
									/>
									<label className="form-check-label">Disable</label>
								</div>
							</div>
						</div>

						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Unit in oz</h6>
							</div>
							<div className="card-body">
								<div className="col-md-12">
									<input type="text" className="form-control" value={row.unit} onChange={unitChange} />
								</div>
								<h6 className="mt-2 fw-bold">Weight</h6>
								<div className="col-md-12">
									<input type="text" className="form-control" value={row.weight} onChange={weightChange} />
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Categories</h6>
							</div>
							<div className="card-body">
								<label className="form-label">Categories Select</label>
								<select
									className="form-select"
									size="3"
									aria-label="size 4 select example"
									value={row.category}
									onChange={categoryChange}
								>
									{categories.map((c) => (
										<option key={c.id} value={c.id}>
											{c.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-8 col-lg-8">
					<div className="card mb-3">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Basic information</h6>
						</div>
						<div className="card-body">
							<form>
								<div className="row g-3 align-items-center">
									<div className="col-md-6">
										<label className="form-label">Name</label>
										<input type="text" className="form-control" value={row.name} onChange={nameChange} />
									</div>

									<div className="col-md-12">
										<label className="form-label">Product Description</label>
										<textarea className="form-control" value={row.description} onChange={descriptionChange} />
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Images</h6>
						</div>
						<div className="card-body">
							<div className="row g-3 align-items-center">
								<div className="col-md-12">
									<label className="form-label">Product Images Upload</label>
									<div id="create-token" className="dropzone">
										{previewImageUrl ? (
											<img src={previewImageUrl} alt="" />
										) : (
											<div className="dz-message ">
												<i className="fa fa-picture-o" aria-hidden="true"></i>
											</div>
										)}
										<input
											id="filesize"
											name="image"
											type="file"
											accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .mp4, .webm, .mp3, awv, .ogg, .glb"
											onChange={handleImageChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ProductAdd;
