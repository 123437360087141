/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { loadScript } from "@paypal/paypal-js";
import { useSelector, useDispatch } from "react-redux";
import braintree from "braintree-web";
import { getCustomerByName, getProductByName } from "../../../Redux/Actions/Action";

const baseURL = "https://mitserver.app:1515";
const base = "https://api-m.sandbox.paypal.com";
const CLIENT_ID = "Ad5JqNeaiBY86kQrM7kIbtYScFSTbv_Ir6JnFR2F866lqOBagc7AHMis7I9G9hIv469xY6-awV6c2Gu5";
const APP_SECRET = "EORl1_mWRQV74qsfgQ1tCb_jUJnM4EoUlZhirCDY3g3mQZ8EgktW9tH6MU4YlC1IQo3I1qaXiyX74uJ8";

function SimpleInvoice() {
	let history = useHistory();
	const newDate = new Date();
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.Mainreducer.customer);
	const product = useSelector((state) => state.Mainreducer.product);

	const [data, setData] = useState();
	const [customerSelected, setCustomerSelected] = useState({ name: "Customer", lastName: "Name", companyName: "" });
	const [offLineOrder, setOffLineOrder] = useState([]);
	const [shippingMethod, setShippingMethod] = useState();
	const [subTotal, setSubTotal] = useState(0);
	const [totalWeight, setTotalWeight] = useState(0);
	const [loading, setLoading] = useState(false);
	const [shippingAmount, setShippingAmount] = useState(0);
	const [showUPS, setShowUPS] = useState(false);
	const [showUSPS, setShowUSPS] = useState(false);
	const [clientToken, setClientToken] = useState(null);

	// useEffect(() => {
	//     loadScript({ "client-id": 'Ad5JqNeaiBY86kQrM7kIbtYScFSTbv_Ir6JnFR2F866lqOBagc7AHMis7I9G9hIv469xY6-awV6c2Gu5' })
	//         .then((paypal) => {
	//             console.log("PAYPAL", paypal);
	//             // If this returns false or the card fields aren't visible, see Step #1.
	//             if (paypal.HostedFields.isEligible()) {
	//                 let orderId;

	//                 // Renders card fields
	//                 paypal.HostedFields.render({
	//                     // Call your server to set up the transaction
	//                     createOrder: () => {
	//                         return fetch("/api/orders", {
	//                             method: 'post'
	//                             // use the "body" param to optionally pass additional order information like
	//                             // product ids or amount.
	//                         })
	//                             .then((res) => res.json())
	//                             .then((orderData) => {
	//                                 orderId = orderData.id; // needed later to complete capture
	//                                 console.log('ORDER', orderData);
	//                                 return orderData.id
	//                             })
	//                     },
	//                     styles: {
	//                         '.valid': {
	//                             color: 'green'
	//                         },
	//                         '.invalid': {
	//                             color: 'red'
	//                         }
	//                     },
	//                     fields: {
	//                         number: {
	//                             selector: "#card-number",
	//                             placeholder: "4111 1111 1111 1111"
	//                         },
	//                         cvv: {
	//                             selector: "#cvv",
	//                             placeholder: "123"
	//                         },
	//                         expirationDate: {
	//                             selector: "#expiration-date",
	//                             placeholder: "MM/YY"
	//                         }
	//                     }
	//                 }).then((cardFields) => {
	//                     console.log("CARD FIELD", cardFields);
	//                     document.querySelector("#card-form").addEventListener("submit", (event) => {
	//                         event.preventDefault();
	//                         cardFields
	//                             .submit({
	//                                 cardholderName: 'Greeks Farrier',
	//                                 billingAddress: {
	//                                     streetAddress: '1303 ST.',
	//                                     extendedAddress: '',
	//                                     region: 'FL',
	//                                     locality: 'Doral',
	//                                     postalCode: '33122',
	//                                     countryCodeAlpha2: '506',
	//                                 },
	//                             })
	//                             .then(() => {
	//                                 fetch(`/api/orders/${orderId}/capture`, {
	//                                     method: "post",
	//                                 })
	//                                     .then((res) => res.json())
	//                                     .then((orderData) => {
	//                                         // Three cases to handle:
	//                                         //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
	//                                         //   (2) Other non-recoverable errors -> Show a failure message
	//                                         //   (3) Successful transaction -> Show confirmation or thank you
	//                                         // This example reads a v2/checkout/orders capture response, propagated from the server
	//                                         // You could use a different API or structure for your 'orderData'
	//                                         var errorDetail =
	//                                             Array.isArray(orderData.details) && orderData.details[0];
	//                                         if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
	//                                             // return actions.restart(); // Recoverable state, per:
	//                                             // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
	//                                         }
	//                                         if (errorDetail) {
	//                                             var msg = "Sorry, your transaction could not be processed.";
	//                                             if (errorDetail.description)
	//                                                 msg += "\n\n" + errorDetail.description;
	//                                             if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
	//                                             return alert(msg); // Show a failure message
	//                                         }
	//                                         // Show a success message or redirect
	//                                         alert("Transaction completed!");
	//                                     });
	//                             })
	//                             .catch((err) => {
	//                                 alert("Payment could not be captured! " + JSON.stringify(err));
	//                             });
	//                     });
	//                 });
	//             } else {
	//                 // Hides card fields if the merchant isn't eligible
	//                 document.querySelector("#card-form").style = 'display: none';
	//             }
	//         })
	//         .catch((err) => {
	//             console.error("failed to load the PayPal JS SDK script", err);
	//         });

	// }, [])

	useEffect(() => {
		// (async () => {
		//     const response = await axios.get(baseURL + "/clientToken")
		//     var data1 = response.data;
		//     var result = data1.result;
		//     var data2 = result.data;
		//     // var deviceData = '';
		//     setClientToken(data2)
		//     console.log("RESULT TOKEN", result);
		//     braintree.client.create({
		//         authorization: data2
		//     }, function (err, clientInstance) {
		//         // Creation of any other components...
		//         braintree.dataCollector.create({
		//             client: clientInstance
		//         }, function (err, dataCollectorInstance) {
		//             if (err) {
		//                 // Handle error in creation of data collector
		//                 return;
		//             }
		//             // At this point, you should access the dataCollectorInstance.deviceData value and provide it
		//             // to your server, e.g. by injecting it into your form as a hidden input.
		//             var deviceData = dataCollectorInstance.deviceData;
		//             braintree.client.create(
		//                 {
		//                     authorization: data2,
		//                 },
		//                 function (err, client) {
		//                     client.request(
		//                         {
		//                             endpoint: "payment_methods/credit_cards",
		//                             method: "post",
		//                             data: {
		//                                 creditCard: {
		//                                     number: "36259600000004",
		//                                     expirationDate: "10/20",
		//                                     cvv: "123",
		//                                     billingAddress: {
		//                                         postalCode: "12345",
		//                                     },
		//                                 },
		//                             },
		//                         },
		//                         async function (err, response) {
		//                             if (response !== null) {
		//                                 console.log("BRAINTREE RESPONSE", response);
		//                                 // Send response.creditCards[0].nonce to your server
		//                                 var nonce = response.creditCards[0].nonce;
		//                                 const response1 = await axios.post(baseURL + "/checkout", {
		//                                     nonce,
		//                                     deviceData
		//                                 })
		//                                 var data2 = response1.data;
		//                                 var result = data2.result;
		//                                 var data3 = result.data;
		//                                 console.log("DATA 3", data3);
		//                             } else {
		//                                 console.log("REVISE LOS DATOS DE TARJETA");
		//                             }
		//                         }
		//                     );
		//                 }
		//             );
		//         });
		//     });
		// })();
	}, []);

	// useEffect(async () => {
	//     var accessToken = await generateAccessToken();
	//     var myHeaders = new Headers();
	//     myHeaders.append("Content-Type", "application/json");
	//     myHeaders.append("Prefer", "return=representation");
	//     myHeaders.append("PayPal-Request-Id", "ca1db200-be6e-4219-8af4-5cb0acbf67eb");
	//     myHeaders.append("Authorization", "Bearer " + accessToken);

	//     var raw = JSON.stringify({
	//         "intent": "CAPTURE",
	//         "purchase_units": [
	//             {
	//                 "items": [
	//                     {
	//                         "name": "T-Shirt",
	//                         "description": "Green XL",
	//                         "quantity": "1",
	//                         "unit_amount": {
	//                             "currency_code": "USD",
	//                             "value": "100.00"
	//                         }
	//                     }
	//                 ],
	//                 "amount": {
	//                     "currency_code": "USD",
	//                     "value": "100.00",
	//                     "breakdown": {
	//                         "item_total": {
	//                             "currency_code": "USD",
	//                             "value": "100.00"
	//                         }
	//                     }
	//                 }
	//             }
	//         ],
	//         "application_context": {
	//             "return_url": "https://example.com/return",
	//             "cancel_url": "https://example.com/cancel"
	//         }
	//     });

	//     var requestOptions = {
	//         method: 'POST',
	//         headers: myHeaders,
	//         body: raw,
	//         redirect: 'follow'
	//     };

	//     fetch("https://api-m.sandbox.paypal.com/v2/checkout/orders", requestOptions)
	//         .then(response => response.text())
	//         .then(result => {
	//             console.log("RESULTS", result)

	//         })
	//         .catch(error => console.log('error', error));
	// }, [])

	async function generateClientToken() {
		const accessToken = await generateAccessToken();
		const response = await fetch(`${base}/v1/identity/generate-token`, {
			method: "post",
			headers: {
				Authorization: `Bearer ${accessToken}`,
				"Accept-Language": "en_US",
				"Content-Type": "application/json",
			},
		});
		const data = await response.json();
		console.log("CLIENT TOKEN", data.client_token);
		return data.client_token;
	}

	async function generateAccessToken() {
		const auth = Buffer.from(CLIENT_ID + ":" + APP_SECRET).toString("base64");
		const response = await fetch(`${base}/v1/oauth2/token`, {
			method: "post",
			body: "grant_type=client_credentials",
			headers: {
				Authorization: `Basic ${auth}`,
			},
		});
		const data = await response.json();
		console.log("ACCESS TOKEN", data.access_token);
		return data.access_token;
	}

	async function searchCustomer(evt) {
		const value = evt.target.value;
		dispatch(getCustomerByName(value));
	}

	function onSelectCustomer(evt) {
		setCustomerSelected(evt);
		console.log("CUSTOMER SELECTED", evt);
		dispatch(getCustomerByName());
	}

	function removeCustomer() {
		setCustomerSelected({ name: "Customer", lastName: "Name", companyName: "" });
	}

	function removeFromCart(p) {
		console.log("REMOVE ITEM", p);
		var code = p.code;
		var otherItems = offLineOrder.filter((x) => x.code !== code);
		setOffLineOrder(otherItems);
		var itemAmount = Number(p.price) * Number(p.quantity);
		var itemWeight = Number(p.weight) * Number(p.quantity);
		var weight = totalWeight - itemWeight;
		var amount = subTotal - itemAmount;
		setTotalWeight(weight);
		setSubTotal(amount);
		console.log("TOTAL WEIGHT", weight);
	}

	async function searchProducts(evt) {
		const value = evt.target.value;
		var customerID = customerSelected.id;
		dispatch(getProductByName(value, customerID));
	}

	function onSelectProduct(p) {
		p.quantity = 1;
		var itemWeight = Number(p.weight);
		var weight = totalWeight + itemWeight;
		setTotalWeight(weight);
		console.log("TOTAL WEIGHT", weight);
		var tempItems = offLineOrder;
		tempItems.push(p);
		setOffLineOrder(tempItems);
		dispatch(getProductByName());
		document.getElementById("searchInput").value = "";
		setSubTotal(Number(offLineOrder.map((item) => Number(item.price)).reduce((prev, curr) => prev + curr, 0)));
	}

	async function getRateUPS(serviceCode) {
		var weightTotal = Math.ceil(totalWeight);
		weightTotal = JSON.stringify(weightTotal);
		console.log("WEIGHT UPS", totalWeight);
		console.log("CUSTOMER", customerSelected);
		const response = await axios.post(baseURL + "/upsGetRates", {
			serviceCode,
			totalWeight: weightTotal,
			customerName: customerSelected.name,
			customerLastName: customerSelected.lastName,
			customerAddress: customerSelected.address,
			customerCity: customerSelected.city,
			customerState: customerSelected.state,
			customerZip: customerSelected.zip,
			customerCountry: customerSelected.country,
		});
		var data1 = response.data;
		var result = data1.result;
		if (result.ok === 1) {
			setLoading(false);
			var amount = result.data;
			console.log("RESP", amount);
			setShippingAmount(amount);
			dispatch({ type: "ADD_SHIPPING", payload: Number(amount) });

			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	async function getRateUSPS(serviceCode) {
		// var weightTotal = Math.ceil(totalWeight)
		// weightTotal = JSON.stringify(weightTotal);
		console.log("WEIGHT USPS", totalWeight);
		// console.log("CUSTOMER", customerSelected);
		const response = await axios.post(baseURL + "/uspsGetRates", {
			serviceCode,
			totalWeight, //: weightTotal,
			customerName: customerSelected.name,
			customerLastName: customerSelected.lastName,
			customerAddress: customerSelected.address,
			customerCity: customerSelected.city,
			customerState: customerSelected.state,
			customerZip: customerSelected.zip,
			customerCountry: customerSelected.country,
		});
		var data1 = response.data;
		var result = data1.result;
		if (result.ok === 1) {
			setLoading(false);
			var data2 = result.data;
			var amount = Number(data2.Rate) + 1; //MIT COMISSION
			console.log("RESP", amount);
			setShippingAmount(amount);
			dispatch({ type: "ADD_SHIPPING", payload: Number(amount) });

			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	function onChangeValue(event) {
		if (event.target.id === "UPS") {
			setShowUPS(true);
			setShowUSPS(false);
			setShippingAmount("");
			getRateUPS("03");
			setShippingMethod("UPS");
		} else {
			setShowUPS(false);
			setShowUSPS(true);
			setShippingAmount("");
			getRateUSPS("03");
			setShippingMethod("USPS");
		}
	}

	async function saveOrder(event) {
		event.preventDefault();

		// Example validation logic
		if (!customerSelected.id) {
			toast.error("Please select a customer", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return; // Stop the function here
		}

		if (offLineOrder.length === 0) {
			toast.error("Please add at least one product to the order", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return; // Stop the function here
		}

		if (!(showUPS || showUSPS)) {
			toast.error("Please select a shipping method", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			return; // Stop the function here
		}

		var saveData = {
			action: "add",
			table: "orders",
			shippingAmount: Number(shippingAmount).toFixed(2),
			totalAmount: Number(shippingAmount) + Number(subTotal),
			customer: customerSelected,
			products: JSON.stringify(offLineOrder),
			nonce: null,
			orderID: null,
			payerID: null,
			paymentID: null,
			discount: null,
			taxes: null,
			shippingMethod,
			totalWeight,
			serviceCode: "03",
			data: { id: "9" }, //JUST FOT NO ERROR ON ACTIONS
		};

		const response = await axios.post(baseURL + "/actions", saveData);
		var data = response.data;
		var result = data.result;
		if (result.ok === 1) {
			history.push("/order-list");
			dispatch({ type: "ADD_SHIPPING", payload: 0 });
			localStorage.removeItem("addedItems");
			localStorage.setItem("totalWeight", 0);
			localStorage.setItem("total", 0);
			toast.success(result.text, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	function changePrice(event, o) {
		console.log("ORDER", o);

		var value = event.target.value;
		var id = o.id;
		var total = Number(value) * Number(o.quantity);

		var modifiedItem = offLineOrder.find((x) => x.id === id);
		modifiedItem.price = value;
		modifiedItem.total = total;
		console.log("MODIFIED", modifiedItem);

		var otherItems = offLineOrder.filter((x) => x.id !== id);
		console.log("FILTER", otherItems);
		otherItems.push(modifiedItem);
		setOffLineOrder(otherItems);
		setSubTotal(Number(offLineOrder.map((item) => Number(item.price)).reduce((prev, curr) => prev + curr, 0)));
	}

	async function changeQty(event, o) {
		var value = event.target.value;

		if (Number(value) > 0) {
			var id = o.id;
			var price = o.price;
			var total = Number(price) * Number(value);
			console.log("QUANTITY", value, total);
			console.log("TOTAL WEIGHT", totalWeight);

			var modifiedItem = offLineOrder.find((x) => x.id === id);
			var oldQty = modifiedItem.quantity;
			modifiedItem.quantity = value;
			modifiedItem.total = total;

			var newWeight = Number(modifiedItem.weight) * Number(value);
			console.log("NEW WEIGHT", newWeight);

			var realWeight = Number(totalWeight) - Number(modifiedItem.weight * Number(oldQty));
			console.log("REAL WEIGHT", realWeight);

			var weight = realWeight + newWeight;
			console.log("TOTAL WEIGHT", weight);
			setTotalWeight(weight);

			var otherItems = offLineOrder.filter((x) => x.id !== id);
			console.log("FILTER", otherItems);
			otherItems.push(modifiedItem);
			setOffLineOrder(otherItems);
			setSubTotal(Number(offLineOrder.map((item) => Number(item.price) * item.quantity).reduce((prev, curr) => prev + curr, 0)));
		}
	}

	// var submitBtn = document.getElementById("my-submit");
	// var form = document.getElementById("my-sample-form");

	// braintree.client.create(
	//     {
	//         authorization: clientToken,
	//     },
	//     clientDidCreate
	// );

	// function clientDidCreate(err, client) {
	//     braintree.hostedFields.create(
	//         {
	//             client: client,
	//             styles: {
	//                 input: {
	//                     "font-size": "16pt",
	//                     color: "#3A3A3A",
	//                 },

	//                 ".number": {
	//                     "font-family": "monospace",
	//                 },

	//                 ".valid": {
	//                     color: "green",
	//                 },
	//             },
	//             fields: {
	//                 number: {
	//                     selector: "#card-number",
	//                 },
	//                 cvv: {
	//                     selector: "#cvv",
	//                 },
	//                 expirationDate: {
	//                     selector: "#expiration-date",
	//                 },
	//             },
	//         },
	//         hostedFieldsDidCreate
	//     );
	// }

	// function hostedFieldsDidCreate(err, hostedFields) {
	//     submitBtn.addEventListener("click", submitHandler.bind(null, hostedFields));
	//     submitBtn.removeAttribute("disabled");
	// }

	// function submitHandler(hostedFields, event) {
	//     event.preventDefault();
	//     submitBtn.setAttribute("disabled", "disabled");

	//     hostedFields.tokenize(function (err, payload) {
	//         if (err) {
	//             submitBtn.removeAttribute("disabled");
	//             console.error(err);
	//         } else {
	//             form["payment_method_nonce"].value = payload.nonce;
	//             form.submit();
	//         }
	//     });
	// }

	return (
		<div className="row justify-content-center">
			<div className="col-lg-12 col-md-12">
				<div className="card p-xl-5 p-lg-4 p-0">
					<div className="card-body">
						<div className="mb-3 pb-3 border-bottom">
							<strong>Date</strong>
							<span className="px-1">{newDate.toString().split("GMT")[0]}</span>
							<span className="float-end">
								{" "}
								<strong>Order id:</strong> #
							</span>
						</div>

						<div className="row mb-4">
							{customerSelected.companyName !== "" ? (
								<div style={{ justifyContent: "space-between" }}>
									<strong>Customer: </strong>{" "}
									{customerSelected.name +
										" - " +
										customerSelected.companyName +
										" - " +
										customerSelected.state +
										" - " +
										customerSelected.country +
										"    "}
									<span>
										<i className="fa fa-trash" style={{ color: "red", textAlign: "end" }} onClick={removeCustomer}></i>
									</span>
								</div>
							) : (
								<div>
									<div className="input-group flex-nowrap input-group-lg">
										<input type="search" className="form-control" placeholder="Search Customer" onChange={searchCustomer} />
										<button type="button" className="input-group-text">
											<i className="fa fa-search"></i>
										</button>
									</div>
									{customer.length > 0 && (
										<select className="form-select" size="3" aria-label="size 4 select example">
											{customer.map((c) => (
												<option key={customerSelected.id} value={c.id} onClick={() => onSelectCustomer(c)}>
													{c.name + " - " + c.companyName + " - " + c.state + " - " + c.country}
												</option>
											))}
										</select>
									)}
								</div>
							)}
						</div>

						<div>
							<div>
								<div className="input-group flex-nowrap input-group-lg">
									<input
										type="search"
										className="form-control"
										placeholder="Search Product"
										id="searchInput"
										onChange={searchProducts}
									/>
									<button type="button" className="input-group-text">
										<i className="fa fa-search"></i>
									</button>
								</div>
								{product.length > 0 && (
									<select className="form-select" size="3" aria-label="size 4 select example">
										{product.map((p) => (
											<option key={p.id} value={p.id} onClick={() => onSelectProduct(p)}>
												{p.name + " Stock: " + p.stock + " Unit: " + p.unit + " Price: " + p.price}
											</option>
										))}
									</select>
								)}
							</div>
							<table className="table table-striped">
								<thead>
									<tr>
										<th className="text-center">code</th>
										<th className="text-center">Item</th>
										<th className="text-center">Unit</th>
										<th className="text-center">Item Cost</th>
										<th className="text-center">Quantity</th>
										<th className="text-center">Total</th>
										<th className="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{offLineOrder.map((o) => (
										<tr key={o.id + o.name}>
											<td className="text-center">{o.code}</td>
											<td>{o.name}</td>
											<td className="text-center">{o.unit}</td>
											<td className="text-center">
												$
												<input
													type="text"
													name="price"
													onChange={(event) => changePrice(event, o)}
													placeholder={o.price}
													style={{ width: "40%" }}
												/>
											</td>
											<td className="text-center">
												<input
													type="text"
													onChange={(event) => changeQty(event, o)}
													placeholder={1}
													style={{ width: "20%" }}
												/>
											</td>
											<td className="text-end">${o.total ? o.total : o.price}</td>
											<td className="text-center">
												<span>
													<i
														className="fa fa-trash"
														style={{ color: "red", textAlign: "end" }}
														onClick={() => removeFromCart(o)}
													></i>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-lg-4 col-sm-5"></div>

							<div className="col-lg-6 col-sm-7 ms-auto">
								<table className="table table-clear">
									<tbody>
										<tr>
											<td>
												<strong></strong>
											</td>
											<td>
												<strong></strong>
											</td>
											<td>
												<strong>Subtotal</strong>
											</td>
											<td className="text-end">${subTotal}</td>
										</tr>
										<tr>
											<td>
												<strong>Shipping Method</strong>
											</td>
											<td>
												{offLineOrder.length !== 0 && (
													<div onChange={onChangeValue} className="d-flex justify-content-around">
														<p>
															<input type="radio" id="UPS" name="radio-group" />
															<label htmlFor="UPS">UPS</label>
														</p>
														<p>
															<input type="radio" id="USPS" name="radio-group" />
															<label htmlFor="USPS">USPS</label>
														</p>
													</div>
												)}
											</td>
											<td></td>
											<td className="text-end">${shippingAmount}</td>
										</tr>
										<tr>
											<td>
												<strong></strong>
											</td>
											<td>
												<strong></strong>
											</td>
											<td>
												<strong>Total</strong>
											</td>
											<td className="text-end">
												<strong>${(Number(shippingAmount) + Number(subTotal)).toFixed(2)}</strong>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-12 text-end">
								{/* <button type="button" className="btn btn-outline-secondary btn-lg my-1"><i className="fa fa-print"></i> Print</button> */}
								<button onClick={saveOrder} type="button" className="btn btn-primary btn-lg my-1">
									<i className="fa fa-save"></i> Save Order
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SimpleInvoice;
