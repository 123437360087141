import { useHistory, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PageHeader1 from "../../components/common/PageHeader1";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { getProducts, getCategories } from "../../Redux/Actions/Action";
const baseURL = "https://mitserver.app:1515";

function ProductList() {
	const history = useHistory();
	const dispatch = useDispatch();
	const rows = useSelector((state) => state.Mainreducer.products);
	const categories = useSelector((state) => state.Mainreducer.categories);
	const imgURL = "https://poly-lite.com/images/product-catalog/";
	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{
			name: "CODE",
			selector: (row) => row.code,
			sortable: true,
		},
		{
			name: "NAME",
			selector: (row) => row.name,
			cell: (row) => (
				<>
					<img className="avatar rounded lg border" src={row.image} alt="" /> <span className="px-2">{row.name}</span>
				</>
			),
			sortable: true,
			minWidth: "200px",
		},
		{
			name: "CATEGORY",
			selector: (row) => row.category,
			sortable: true,
			cell: (row) => <span>{fcat(row.category)}</span>,
		},
		{
			name: "UNIT",
			selector: (row) => row.unit,
			sortable: true,
		},
		{
			name: "WEIGHT",
			selector: (row) => row.weight,
			sortable: true,
		},
		{
			name: "PRICE",
			selector: (row) => row.price,
			sortable: true,
		},
		{
			name: "STOCK",
			selector: (row) => row.stock,
			sortable: true,
		},
		{
			name: "STATUS",
			selector: (row) => row.status,
			sortable: true,
			cell: (row) => <span className={`badge ${row.status === "Enable" ? "bg-success" : "bg-danger"}`}>{row.status}</span>,
		},
		{
			name: "ACTION",
			// selector: (row) => row.status,
			sortable: true,
			cell: (row) => (
				<div className="btn-group" role="group" aria-label="Basic outlined example">
					<button onClick={() => editButton(row)} type="button" className="btn btn-outline-secondary">
						<i className="icofont-edit text-success"></i>
					</button>
					<button
						onClick={() => {
							deleteProduct(row);
						}}
						type="button"
						className="btn btn-outline-secondary deleterow"
					>
						<i className="icofont-ui-delete text-danger"></i>
					</button>
				</div>
			),
		},
	];

	useEffect(() => {
		if (rows.length === 0) {
			console.log("EMPTY rows");
			dispatch(getProducts);
			dispatch(getCategories);
		}
	}, []);

	function fcat(params) {
		var found = categories.find((element) => {
			return element.id === params;
		});
		return found.name;
	}

	// async function getCategories() {
	//     const response = await axios.post(baseURL + "/getLists", {
	//         list: 'categories'
	//     })
	//     var resp = response.data;
	//     var result = resp.result;
	//     var data = result.data;
	//     console.log("CATEGORIES", data);
	//     setCategories(data);
	// }

	const editButton = (row, event) => {
		history.push({
			pathname: "/product-edit",
			state: row,
		});
	};

	async function deleteProduct(selected) {
		const response = await axios.post(baseURL + "/actions", {
			action: "delete",
			table: "products",
			data: selected,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP", result);
		dispatch(getProducts);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}

	return (
		<div className="container-xxl">
			<div className="row align-items-center">
				<div className="border-0 mb-4">
					<div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
						<h3 className="fw-bold mb-0">Products List</h3>
						<Link to={"product-Add"} className="btn btn-primary btn-set-task w-sm-100">
							<i className="icofont-plus-circle me-2 fs-6"></i>Add Product
						</Link>
					</div>
				</div>
			</div>
			<div className="row g-3 mb-3">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
								<div className="row">
									<div className="col-sm-12">
										<DataTable
											columns={columns}
											data={rows}
											defaultSortField="title"
											pagination
											selectableRows={false}
											className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
											highlightOnHover={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ProductList;
