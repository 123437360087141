import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import AddressBlock from "../../components/Orders/OrdersDetail/AddressBlock";
import OrderSummeryBlock from "../../components/Orders/OrdersDetail/OrderSummeryBlock";
import StatusOrderBlock from "../../components/Orders/OrdersDetail/StatusOrderBlock";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { getOrderItems } from "../../Redux/Actions/Action";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const baseURL = "https://mitserver.app:1515";

function OrderDetail() {
	let history = useHistory();
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.Mainreducer.customer);
	const [order, setOrder] = useState("");
	const [orderItems, setOrderItems] = useState([]);
	const [address, setAddress] = useState("");
	const [card, setCard] = useState("");
	const [OrderDetailData, setOrderDetailData] = useState([
		{
			mainbgColor: "alert-success",
			bgColor: "bg-success",
			icon: "fa fa-shopping-cart fa-lg",
			name: "Order Created at",
			secondName: "",
		},
		{
			name: "Name",
			secondName: "",
			icon: "fa fa-user fa-lg",
			bgColor: "bg-danger",
			mainbgColor: "alert-danger",
		},
		{
			mainbgColor: "alert-warning",
			bgColor: "bg-warning",
			icon: "fa fa-envelope fa-lg",
			name: "Email",
			secondName: "",
		},
		{
			mainbgColor: "alert-info",
			bgColor: "bg-info",
			icon: "fa fa-phone-square fa-lg",
			name: "Contact No",
			secondName: "",
		},
	]);
	const OrderDiv = [
		{
			addressName: "Delivery Address",
			blockNumber: "A-510",
			address: "81 Fulton London",
			pincode: "385467",
			phone: "202-458-4568",
		},
		{
			addressName: "Billing Address",
			blockNumber: "A-510",
			address: "81 Fulton London",
			pincode: "385467",
			phone: "202-458-4568",
		},
	];
	const [table_row, setTable_row] = useState([]);
	const columns = () => {
		return [
			// {
			//     name: " PRODUCT IMAGE",
			//     selector: (row) => row.image,
			//     cell: row => <><img className="avatar rounded lg border" src={row.image} alt="" /></>,
			//     sortable: true,
			// },
			{
				name: "PRODUCT NAME",
				selector: (row) => row.productName,
				sortable: true,
				cell: (row) => (
					<>
						<div className="row">
							<h6 className="title ">{row.productName}</h6>
							<span className="d-block fs-6 text-primary">{row.preOrder}</span>
						</div>
					</>
				),
			},
			{
				name: "QUANTITY",
				selector: (row) => row.quanty,
				sortable: true,
			},
			{
				name: "PRICE",
				selector: (row) => row.price,
				sortable: true,
			},
		];
	};

	useEffect(() => {
		var orderPassed = localStorage.getItem("order");
		orderPassed = JSON.parse(orderPassed);
		getCard(orderPassed.customerID);
		setOrder(orderPassed);
		var address1 = JSON.parse(orderPassed.shippingAddress);
		setAddress(address1);
	}, []);

	useEffect(() => {
		applyCustomer(order, customer);
	}, [customer]);

	function getCard(userID) {
		axios
			.post(
				baseURL + "/getCardsById",
				{
					userID: userID,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((res) => {
				console.log("RES", res.data.result);
				const result = res.data.result;
				setCard(result.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function applyCustomer(orderPassed, customer) {
		setTimeout(() => {
			setOrderDetailData([
				{
					mainbgColor: "alert-success",
					bgColor: "bg-success",
					icon: "fa fa-shopping-cart fa-lg",
					name: "Order Created at",
					secondName: orderPassed.createdDate,
				},
				{
					name: "Name",
					secondName: customer.name,
					icon: "fa fa-user fa-lg",
					bgColor: "bg-danger",
					mainbgColor: "alert-danger",
				},
				{
					mainbgColor: "alert-warning",
					bgColor: "bg-warning",
					icon: "fa fa-envelope fa-lg",
					name: "Email",
					secondName: customer.email,
				},
				{
					mainbgColor: "alert-info",
					bgColor: "bg-info",
					icon: "fa fa-phone-square fa-lg",
					name: "Contact No",
					secondName: customer.phone,
				},
			]);
		}, 1000);

		setTimeout(() => {
			var orderItems = localStorage.getItem("orderItems");

			orderItems = JSON.parse(orderItems);
			var rows = [];
			orderItems.forEach((x) => {
				var row = {
					// image: Product3,
					productName: x.name,
					preOrder: x.code,
					price: x.price,
					quanty: x.quantity,
				};
				rows.push(row);
			});
			setTable_row(rows);
			// localStorage.removeItem('orderItems');
		}, 1000);
	}

	function showLabel(label) {
		label = JSON.parse(label);
		console.log("LABEL", label);
		let data = "data:image/png;base64," + label;
		let w = window.open("about:blank");
		let image = new Image();
		image.src = data;
		setTimeout(function () {
			w.document.write(image.outerHTML);
		}, 0);
	}

	async function updateOrder(event) {
		event.preventDefault();
		const updatedOrder = {
			...order,
			table: "orders",
			action: "update",
			data: { id: "9" },
		};
		console.log("ORDER", updatedOrder);
		try {
			const response = await axios.post(baseURL + "/actions", updatedOrder);
			const result = response.data.result;

			if (result.ok === 1) {
				history.push("/order-list");
				toast.success(result.text, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			} else {
				// Handle the situation where 'result.ok' is not 1
				// Perhaps show an error message using toast.error
			}
		} catch (error) {
			console.error("Error updating order:", error);
			toast.error("Error updating order", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
	}

	return (
		<div className="body d-flex py-3">
			<div className="container-xxl">
				<div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
					<h3 className="fw-bold mb-0">Order # {order.id}</h3>
				</div>
				{customer && order !== "" && (
					<div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
						{OrderDetailData.map((d, i) => {
							return (
								<div key={"s" + d.name} className="col">
									<div className={`${d.mainbgColor} alert mb-0`}>
										<div className="d-flex align-items-center">
											<div className={`avatar rounded no-thumbnail ${d.bgColor} text-light`}>
												<i className={d.icon}></i>
											</div>
											<div className="flex-fill ms-3 text-truncate">
												<div className="h6 mb-0">{d.name}</div>
												<span className="small">{d.secondName}</span>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<div className="row g-3 mb-3 row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-deck">
					{address !== "" && (
						<div className="col">
							<div className="card">
								<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
									<h6 className="mb-0 fw-bold ">Shipping Address</h6>
								</div>
								<div className="card-body">
									<div className="row g-3">
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">Country:</label>
											<span>
												<strong>{address.country}</strong>
											</span>
										</div>
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">City:</label>
											<span>
												<strong>{address.city}</strong>
											</span>
										</div>
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">State:</label>
											<span>
												<strong>{address.state}</strong>
											</span>
										</div>
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">Address:</label>
											<span>
												<strong>{address.address}</strong>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{address !== "" && (
						<div className="col">
							<div className="card">
								<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
									<h6 className="mb-0 fw-bold ">Shipping Method</h6>
								</div>
								<div className="card-body">
									<div className="row g-3">
										{/* <div className="col-12">
                                            <label className="form-label col-6 col-sm-5">Currier:</label>
                                            <span><strong>UPS</strong></span>
                                        </div> */}
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">Currier</label>
											<span>
												<strong>{order.shippingMethod}</strong>
											</span>
										</div>
										<div className="col-12">
											<label className="form-label col-6 col-sm-5">Tracking Number:</label>
											<span>
												<strong>{order.trackingNumber}</strong>
											</span>
										</div>
										<div className="col-12 ">
											<button onClick={() => showLabel(order.shippingLabel)} className="btn btn-primary text-uppercase">
												Print Label
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="col">
						<div className="card">
							<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
								<h6 className="mb-0 fw-bold ">Payment Data</h6>
								{/* <a href="#!" className="text-muted">Download</a> */}
							</div>
							<div className="card-body">
								<div className="row g-3">
									<div className="col-12">
										<label className="form-label col-6 col-sm-5">Card Number: </label>
										<span>
											<strong>{card.cardNumber}</strong>
										</span>
									</div>
									<div className="col-12">
										<label className="form-label col-6 col-sm-5">Expiry: </label>
										<span>
											<strong>{card.exp}</strong>
										</span>
									</div>
									<div className="col-12 ">
										<label className="form-label col-6 col-sm-5">CVC: </label>
										<span
											className="col-6 col-sm-5"
											style={{
												wordBreak: "break-all",
											}}>
											<strong>{card.ccv}</strong>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row g-3 mb-3 container-xxl align-self-center">
				<div className="col-xl-12 col-xxl-8">
					<div className="card">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Order Summary</h6>
						</div>
						<div className="card-body">
							<div className="product-cart">
								<div className="checkout-table table-responsive">
									<div id="myCartTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
										<div className="row">
											<div className="col-sm-12">
												<DataTable
													columns={columns()}
													data={table_row}
													defaultSortField="title"
													pagination
													selectableRows={false}
													className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
													highlightOnHover={true}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="checkout-coupon-total checkout-coupon-total-2 d-flex flex-wrap justify-content-end">
									<div className="checkout-total">
										<div className="single-total">
											<p className="value">Subotal Price:</p>
											<p className="price">${order.totalAmount - order.shippingAmount}</p>
										</div>
										<div className="single-total">
											<p className="value">Shipping Cost (+):</p>
											<p className="price">${order.shippingAmount}</p>
										</div>
										{/* <div className="single-total">
                                            <p className="value">Discount (-):</p>
                                            <p className="price">$10.00</p>
                                        </div> */}
										{/* <div className="single-total">
                                            <p className="value">Tax(18%):</p>
                                            <p className="price">$198.00</p>
                                        </div> */}
										<div className="single-total total-payable">
											<p className="value">Total Payable:</p>
											<p className="price">${order.totalAmount}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-12 col-xxl-4">
					<div className="card mb-3">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Status</h6>
						</div>
						<div className="card-body">
							<form>
								<div className="row g-3 align-items-center">
									<div className="col-md-12">
										<label className="form-label">Order Status</label>
										<select
											className="form-select"
											value={order.status}
											onChange={(e) => setOrder({ ...order, status: e.target.value })}>
											<option value="Processing">Processing</option>
											<option value="Shipped">Shipped</option>
											<option value="Complete">Complete</option>
										</select>
									</div>
									{/* <div className="col-md-12">
                                        <label className="form-label">Order Transection</label>
                                        <select className="form-select">
                                            <option value="1">Completed</option>
                                            <option value="2">Fail</option>
                                        </select>
                                    </div> */}
									<div className="col-md-12">
										<label htmlFor="comment" className="form-label">
											Note
										</label>
										<textarea
											className="form-control"
											id="comment"
											rows="4"
											value={order.notes}
											onChange={(e) => setOrder({ ...order, notes: e.target.value })}></textarea>
									</div>
								</div>
								<div className="col-lg-12 text-end">
									{/* <button type="button" className="btn btn-outline-secondary btn-lg my-1"><i className="fa fa-print"></i> Print</button> */}
									<button onClick={updateOrder} type="button" className="btn btn-primary btn-lg my-1">
										<i className="fa fa-save"></i> Update Order
									</button>
								</div>
								{/* <button type="button" className="btn btn-primary mt-4 text-uppercase">Submit</button> */}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderDetail;
