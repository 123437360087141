import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { CustomerData } from "../../components/Data/CustomerData";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { getCustomers } from "../../Redux/Actions/Action";
import Avatar1 from "../../assets/images/xs/avatar1.svg";
import Avatar2 from "../../assets/images/xs/avatar2.svg";
import Avatar3 from "../../assets/images/xs/avatar3.svg";
import Avatar4 from "../../assets/images/xs/avatar4.svg";
import Avatar5 from "../../assets/images/xs/avatar5.svg";
import Avatar6 from "../../assets/images/xs/avatar6.svg";
import Avatar7 from "../../assets/images/xs/avatar7.svg";
import Avatar8 from "../../assets/images/xs/avatar8.svg";
import Avatar9 from "../../assets/images/xs/avatar9.svg";
const baseURL = "https://mitserver.app:1515";

function CustomerList() {
	const dispatch = useDispatch();
	const customers = useSelector((state) => state.Mainreducer.customers);

	// const [table_row, setTable_row] = useState([...CustomerData.rows]);
	const [ismodal, setIsmodal] = useState(false);
	const [iseditmodal, setIseditmodal] = useState(false);
	const [iscardmodal, setIscardmodal] = useState(false);
	const [row, setRow] = useState([]);
	const [card, setCard] = useState([]);

	const columns = () => {
		return [
			{
				name: " ID",
				selector: (row) => row.id,
				sortable: true,
			},
			{
				name: "CUSTOMER",
				selector: (row) => row.name,
				cell: (row) => (
					<>
						<img className="avatar rounded lg border" src={getImage(row.image)} alt="" /> <span className="px-2">{row.name}</span>
					</>
				),
				sortable: true,
				minWidth: "200px",
			},
			{
				name: "E-MAIL",
				selector: (row) => row.email,
				sortable: true,
			},
			{
				name: "PHONE",
				selector: (row) => row.phone,
				sortable: true,
			},
			{
				name: "COUNTRY",
				selector: (row) => row.country,
				sortable: true,
			},
			{
				name: "TOTAL ORDER",
				selector: (row) => row.orders,
				sortable: true,
			},
			{
				name: "ACTION",
				selector: (row) => {},
				sortable: true,
				cell: (row) => (
					<div className="btn-group" role="group" aria-label="Basic outlined example">
						<button
							onClick={() => {
								console.log("SELECTED ROW", row);
								setIseditmodal(true);
								setRow(row);
							}}
							type="button"
							className="btn btn-outline-secondary">
							<i className="icofont-edit text-success"></i>
						</button>
						<button
							onClick={() => {
								getCardByID(row);
							}}
							type="button"
							className="btn btn-outline-secondary">
							<i className="icofont-credit-card text-info"></i>
						</button>
						<button
							type="button"
							onClick={() => {
								deleteCustomer(row);
							}}
							className="btn btn-outline-secondary deleterow">
							<i className="icofont-ui-delete text-danger"></i>
						</button>
					</div>
				),
			},
		];
	};

	useEffect(() => {
		console.log(" CUSTOMERS", customers);
		if (customers.length === 0) {
			console.log("EMPTY CUSTOMERS");
			dispatch(getCustomers);
		}
	}, []);

	async function getCardByID(x) {
		console.log("ROW", x.id);
		const response = await axios.post(baseURL + "/getCardByID", {
			userID: x.id,
		});
		var resp = response.data;
		var result = resp.result;
		console.log("CARD RESP", result);

		if (result.ok) {
			var data = result.data;
			console.log("CARD RESP", data[0]);
			setCard(data[0]);
		}
		setIscardmodal(true);
		setRow(x);
	}

	function getImage(image) {
		switch (image) {
			case "1":
				return Avatar1;
			case "2":
				return Avatar2;
			case "3":
				return Avatar3;
			case "4":
				return Avatar4;
			case "5":
				return Avatar5;
			case "5":
				return Avatar5;
			case "6":
				return Avatar6;
			case "7":
				return Avatar7;
			case "8":
				return Avatar8;
			case "9":
				return Avatar9;
			default:
				break;
		}
	}

	const handleClose = () => {
		setIsmodal(false);
		console.log("CLOSE");
	};

	const handleShow = () => {
		setIsmodal(true);
		console.log("SHOW");
	};

	async function addCustomer() {
		const response = await axios.post(baseURL + "/actions", {
			action: "add",
			table: "customers",
			data: row,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP", result);
		dispatch(getCustomers);

		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
		handleClose();
	}

	async function editCustomer() {
		const response = await axios.post(baseURL + "/actions", {
			action: "update",
			table: "customers",
			data: row,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP", result);
		dispatch(getCustomers);
		setIseditmodal(false);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}

	async function deleteCustomer(selected) {
		const response = await axios.post(baseURL + "/actions", {
			action: "delete",
			table: "customers",
			data: selected,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP", result);
		dispatch(getCustomers);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}

	function nameChange(e) {
		setRow({
			...row,
			name: e.target.value,
		});
	}

	function companyNameChange(e) {
		setRow({
			...row,
			companyName: e.target.value,
		});
	}

	function countryChange(e) {
		setRow({
			...row,
			country: e.target.value,
		});
	}

	function cityChange(e) {
		setRow({
			...row,
			city: e.target.value,
		});
	}

	function stateChange(e) {
		setRow({
			...row,
			state: e.target.value,
		});
	}

	function postcodeChange(e) {
		console.log("POSTCODE", e.target.value);
		setRow({
			...row,
			zip: e.target.value,
		});
	}

	function addressChange(e) {
		setRow({
			...row,
			address: e.target.value,
		});
	}

	function emailChange(e) {
		setRow({
			...row,
			email: e.target.value,
		});
	}

	function phoneChange(e) {
		setRow({
			...row,
			phone: e.target.value,
		});
	}

	function passwordChange(e) {
		setRow({
			...row,
			password: e.target.value,
		});
	}

	function cardNameChange(e) {
		setCard({
			...card,
			cardName: e.target.value,
		});
	}

	function cardNumberChange(e) {
		setCard({
			...card,
			cardNumber: e.target.value,
		});
	}

	function expDateChange(e) {
		setCard({
			...card,
			exp: e.target.value,
		});
	}

	function ccvChange(e) {
		setCard({
			...card,
			ccv: e.target.value,
		});
	}

	async function addCard() {
		const cardWithUserId = {
			...card,
			id: row.id,
		};
		console.log("CARD", card);
		const response = await axios.post(baseURL + "/actions", {
			action: "add",
			table: "cards",
			data: cardWithUserId,
		});
		var data = response.data;
		var result = data.result;
		console.log("RESP CARD", result);
		// dispatch(getCustomers);
		setIscardmodal(false);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}

	return (
		<div className="body d-flex py-lg-3 py-md-2">
			<div className="container-xxl">
				<PageHeader1
					pagetitle="Customers Information"
					modalbutton={() => {
						return (
							<div className="col-auto d-flex w-sm-100">
								<button
									type="button"
									onClick={handleShow}
									className="btn btn-primary btn-set-task w-sm-100"
									data-bs-toggle="modal"
									data-bs-target="#expadd">
									<i className="icofont-plus-circle me-2 fs-6"></i>
									Add Customers
								</button>
							</div>
						);
					}}
				/>
				<div className="row clearfix g-3">
					<div className="col-sm-12">
						<div className="card mb-3">
							<div className="card-body">
								<div id="myProjectTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
									<div className="row">
										<div className="col-sm-12">
											<DataTable
												columns={columns()}
												data={customers}
												defaultSortField="title"
												pagination
												selectableRows={false}
												className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
												highlightOnHover={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				show={iseditmodal}
				onHide={() => {
					setIseditmodal(false);
				}}
				className=""
				style={{ display: "block" }}>
				<Modal.Header className="modal-header" closeButton>
					<h5 className="modal-title  fw-bold" id="expeditLabel">
						{" "}
						Edit Customers
					</h5>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div className="deadline-form">
						<form>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="item" className="form-label">
										Customers Name
									</label>
									<input type="text" className="form-control" id="cname" value={row.name} onChange={nameChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="ccname" className="form-label">
										Company Name
									</label>
									<input type="text" className="form-control" id="ccname" value={row.companyName} onChange={companyNameChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="ccountry" className="form-label">
										Country
									</label>
									<select className="form-control" id="ccountry" value={row.country} onChange={countryChange}>
										<option value="">Seleccione un país</option>
										<option value="AF">Afganistán</option>
										<option value="AX">Islas Áland</option>
										<option value="AL">Albania</option>
										<option value="DZ">Argelia</option>
										<option value="AS">Samoa Americana</option>
										<option value="AD">Andorra</option>
										<option value="AO">Angola</option>
										<option value="AI">Anguila</option>
										<option value="AQ">Antártida</option>
										<option value="AG">Antigua y Barbuda</option>
										<option value="AR">Argentina</option>
										<option value="AM">Armenia</option>
										<option value="AW">Aruba</option>
										<option value="AU">Australia</option>
										<option value="AT">Austria</option>
										<option value="AZ">Azerbaiyán</option>
										<option value="BS">Bahamas</option>
										<option value="BH">Baréin</option>
										<option value="BD">Bangladés</option>
										<option value="BB">Barbados</option>
										<option value="BY">Bielorrusia</option>
										<option value="BE">Bélgica</option>
										<option value="BZ">Belice</option>
										<option value="BJ">Benín</option>
										<option value="BM">Bermudas</option>
										<option value="BT">Bután</option>
										<option value="BO">Bolivia</option>
										<option value="BA">Bosnia y Herzegovina</option>
										<option value="BW">Botsuana</option>
										<option value="BV">Isla Bouvet</option>
										<option value="BR">Brasil</option>
										<option value="IO">Territorio Británico del Océano Índico</option>
										<option value="BN">Brunéi</option>
										<option value="BG">Bulgaria</option>
										<option value="BF">Burkina Faso</option>
										<option value="BI">Burundi</option>
										<option value="CV">Cabo Verde</option>
										<option value="KH">Camboya</option>
										<option value="CM">Camerún</option>
										<option value="CA">Canadá</option>
										<option value="KY">Islas Caimán</option>
										<option value="CF">República Centroafricana</option>
										<option value="TD">Chad</option>
										<option value="CL">Chile</option>
										<option value="CN">China</option>
										<option value="CX">Isla de Navidad</option>
										<option value="CC">Islas Cocos</option>
										<option value="CO">Colombia</option>
										<option value="KM">Comoras</option>
										<option value="CG">Congo</option>
										<option value="CD">Congo (Rep. Dem.)</option>
										<option value="CK">Islas Cook</option>
										<option value="CR">Costa Rica</option>
										<option value="CI">Costa de Marfil</option>
										<option value="HR">Croacia</option>
										<option value="CU">Cuba</option>
										<option value="CW">Curazao</option>
										<option value="CY">Chipre</option>
										<option value="CZ">Chequia</option>
										<option value="DK">Dinamarca</option>
										<option value="DJ">Yibuti</option>
										<option value="DM">Dominica</option>
										<option value="DO">República Dominicana</option>
										<option value="EC">Ecuador</option>
										<option value="EG">Egipto</option>
										<option value="SV">El Salvador</option>
										<option value="AE">Emiratos Árabes Unidos</option>
										<option value="ER">Eritrea</option>
										<option value="SK">Eslovaquia</option>
										<option value="SI">Eslovenia</option>
										<option value="ES">España</option>
										<option value="US">Estados Unidos</option>
										<option value="EE">Estonia</option>
										<option value="ET">Etiopía</option>
										<option value="FK">Islas Malvinas</option>
										<option value="FO">Islas Feroe</option>
										<option value="FJ">Fiyi</option>
										<option value="FI">Finlandia</option>
										<option value="FR">Francia</option>
										<option value="GA">Gabón</option>
										<option value="GM">Gambia</option>
										<option value="GE">Georgia</option>
										<option value="GH">Ghana</option>
										<option value="GI">Gibraltar</option>
										<option value="GR">Grecia</option>
										<option value="GL">Groenlandia</option>
										<option value="GD">Granada</option>
										<option value="GP">Guadalupe</option>
										<option value="GU">Guam</option>
										<option value="GT">Guatemala</option>
										<option value="GG">Guernsey</option>
										<option value="GN">Guinea</option>
										<option value="GW">Guinea-Bisáu</option>
										<option value="GY">Guyana</option>
										<option value="HT">Haití</option>
										<option value="HM">Islas Heard y McDonald</option>
										<option value="HN">Honduras</option>
										<option value="HK">Hong Kong</option>
										<option value="HU">Hungría</option>
										<option value="IS">Islandia</option>
										<option value="IN">India</option>
										<option value="ID">Indonesia</option>
										<option value="IR">Irán</option>
										<option value="IQ">Irak</option>
										<option value="IE">Irlanda</option>
										<option value="IM">Isla de Man</option>
										<option value="IL">Israel</option>
										<option value="IT">Italia</option>
										<option value="JM">Jamaica</option>
										<option value="JP">Japón</option>
										<option value="JE">Jersey</option>
										<option value="JO">Jordania</option>
										<option value="KZ">Kazajistán</option>
										<option value="KE">Kenia</option>
										<option value="KI">Kiribati</option>
										<option value="KW">Kuwait</option>
										<option value="KG">Kirguistán</option>
										<option value="LA">Laos</option>
										<option value="LV">Letonia</option>
										<option value="LB">Líbano</option>
										<option value="LS">Lesoto</option>
										<option value="LR">Liberia</option>
										<option value="LY">Libia</option>
										<option value="LI">Liechtenstein</option>
										<option value="LT">Lituania</option>
										<option value="LU">Luxemburgo</option>
										<option value="MO">Macao</option>
										<option value="MG">Madagascar</option>
										<option value="MW">Malaui</option>
										<option value="MY">Malasia</option>
										<option value="MV">Maldivas</option>
										<option value="ML">Malí</option>
										<option value="MT">Malta</option>
										<option value="MH">Islas Marshall</option>
										<option value="MQ">Martinica</option>
										<option value="MR">Mauritania</option>
										<option value="MU">Mauricio</option>
										<option value="YT">Mayotte</option>
										<option value="MX">México</option>
										<option value="FM">Micronesia</option>
										<option value="MD">Moldavia</option>
										<option value="MC">Mónaco</option>
										<option value="MN">Mongolia</option>
										<option value="ME">Montenegro</option>
										<option value="MS">Montserrat</option>
										<option value="MA">Marruecos</option>
										<option value="MZ">Mozambique</option>
										<option value="MM">Myanmar (Birmania)</option>
										<option value="NA">Namibia</option>
										<option value="NR">Nauru</option>
										<option value="NP">Nepal</option>
										<option value="NL">Países Bajos</option>
										<option value="NC">Nueva Caledonia</option>
										<option value="NZ">Nueva Zelanda</option>
										<option value="NI">Nicaragua</option>
										<option value="NE">Níger</option>
										<option value="NG">Nigeria</option>
										<option value="NU">Niue</option>
										<option value="NF">Isla Norfolk</option>
										<option value="KP">Corea del Norte</option>
										<option value="MP">Islas Marianas del Norte</option>
										<option value="NO">Noruega</option>
										<option value="OM">Omán</option>
										<option value="PK">Pakistán</option>
										<option value="PW">Palaos</option>
										<option value="PS">Palestina</option>
										<option value="PA">Panamá</option>
										<option value="PG">Papúa Nueva Guinea</option>
										<option value="PY">Paraguay</option>
										<option value="PE">Perú</option>
										<option value="PH">Filipinas</option>
										<option value="PN">Islas Pitcairn</option>
										<option value="PL">Polonia</option>
										<option value="PT">Portugal</option>
										<option value="PR">Puerto Rico</option>
										<option value="QA">Catar</option>
										<option value="RE">Reunión</option>
										<option value="RO">Rumanía</option>
										<option value="RU">Rusia</option>
										<option value="RW">Ruanda</option>
										<option value="BL">San Bartolomé</option>
										<option value="SH">Santa Elena, Ascensión y Tristán de Acuña</option>
										<option value="KN">San Cristóbal y Nieves</option>
										<option value="LC">Santa Lucía</option>
										<option value="MF">San Martín (parte francesa)</option>
										<option value="PM">San Pedro y Miquelón</option>
										<option value="VC">San Vicente y las Granadinas</option>
										<option value="WS">Samoa</option>
										<option value="SM">San Marino</option>
										<option value="ST">Santo Tomé y Príncipe</option>
										<option value="SA">Arabia Saudita</option>
										<option value="SN">Senegal</option>
										<option value="RS">Serbia</option>
										<option value="SC">Seychelles</option>
										<option value="SL">Sierra Leona</option>
										<option value="SG">Singapur</option>
										<option value="SX">San Martín (parte holandesa)</option>
										<option value="SK">Eslovaquia</option>
										<option value="SI">Eslovenia</option>
										<option value="SB">Islas Salomón</option>
										<option value="SO">Somalia</option>
										<option value="ZA">Sudáfrica</option>
										<option value="GS">Islas Georgia del Sur y Sandwich del Sur</option>
										<option value="KR">Corea del Sur</option>
										<option value="SS">Sudán del Sur</option>
										<option value="ES">España</option>
										<option value="LK">Sri Lanka</option>
										<option value="SD">Sudán</option>
										<option value="SR">Surinam</option>
										<option value="SJ">Svalbard y Jan Mayen</option>
										<option value="SZ">Suazilandia</option>
										<option value="SE">Suecia</option>
										<option value="CH">Suiza</option>
										<option value="SY">Siria</option>
										<option value="TW">Taiwán</option>
										<option value="TJ">Tayikistán</option>
										<option value="TZ">Tanzania</option>
										<option value="TH">Tailandia</option>
										<option value="TL">Timor-Leste</option>
										<option value="TG">Togo</option>
										<option value="TK">Tokelau</option>
										<option value="TO">Tonga</option>
										<option value="TT">Trinidad y Tobago</option>
										<option value="TN">Túnez</option>
										<option value="TR">Turquía</option>
										<option value="TM">Turkmenistán</option>
										<option value="TC">Islas Turcas y Caicos</option>
										<option value="TV">Tuvalu</option>
										<option value="UG">Uganda</option>
										<option value="UA">Ucrania</option>
										<option value="AE">Emiratos Árabes Unidos</option>
										<option value="GB">Reino Unido</option>
										<option value="US">Estados Unidos</option>
										<option value="UM">Islas menores alejadas de los Estados Unidos</option>
										<option value="UY">Uruguay</option>
										<option value="UZ">Uzbekistán</option>
										<option value="VU">Vanuatu</option>
										<option value="VA">Ciudad del Vaticano</option>
										<option value="VE">Venezuela</option>
										<option value="VN">Vietnam</option>
										<option value="VG">Islas Vírgenes Británicas</option>
										<option value="VI">Islas Vírgenes de los Estados Unidos</option>
										<option value="WF">Wallis y Futuna</option>
										<option value="EH">Sahara Occidental</option>
										<option value="YE">Yemen</option>
										<option value="ZM">Zambia</option>
										<option value="ZW">Zimbabue</option>
									</select>
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										City
									</label>
									<input type="text" className="form-control" id="ccity" value={row.city} onChange={cityChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="cstate" className="form-label">
										State
									</label>
									<select className="form-control" id="cstate" value={row.state} onChange={stateChange}>
										<option value="">Select a state</option>
										<option value="AL">Alabama</option>
										<option value="AK">Alaska</option>
										<option value="AZ">Arizona</option>
										<option value="AR">Arkansas</option>
										<option value="CA">California</option>
										<option value="CO">Colorado</option>
										<option value="CT">Connecticut</option>
										<option value="DE">Delaware</option>
										<option value="FL">Florida</option>
										<option value="GA">Georgia</option>
										<option value="HI">Hawaii</option>
										<option value="ID">Idaho</option>
										<option value="IL">Illinois</option>
										<option value="IN">Indiana</option>
										<option value="IA">Iowa</option>
										<option value="KS">Kansas</option>
										<option value="KY">Kentucky</option>
										<option value="LA">Louisiana</option>
										<option value="ME">Maine</option>
										<option value="MD">Maryland</option>
										<option value="MA">Massachusetts</option>
										<option value="MI">Michigan</option>
										<option value="MN">Minnesota</option>
										<option value="MS">Mississippi</option>
										<option value="MO">Missouri</option>
										<option value="MT">Montana</option>
										<option value="NE">Nebraska</option>
										<option value="NV">Nevada</option>
										<option value="NH">New Hampshire</option>
										<option value="NJ">New Jersey</option>
										<option value="NM">New Mexico</option>
										<option value="NY">New York</option>
										<option value="NC">North Carolina</option>
										<option value="ND">North Dakota</option>
										<option value="OH">Ohio</option>
										<option value="OK">Oklahoma</option>
										<option value="OR">Oregon</option>
										<option value="PA">Pennsylvania</option>
										<option value="RI">Rhode Island</option>
										<option value="SC">South Carolina</option>
										<option value="SD">South Dakota</option>
										<option value="TN">Tennessee</option>
										<option value="TX">Texas</option>
										<option value="UT">Utah</option>
										<option value="VT">Vermont</option>
										<option value="VA">Virginia</option>
										<option value="WA">Washington</option>
										<option value="WV">West Virginia</option>
										<option value="WI">Wisconsin</option>
										<option value="WY">Wyoming</option>
									</select>
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										Post Code
									</label>
									<input type="text" className="form-control" id="cpost" value={row.zip} onChange={postcodeChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="depone" className="form-label">
										Address
									</label>
									<input type="text" className="form-control" id="caddress" value={row.address} onChange={addressChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="abc11" className="form-label">
										E-mail
									</label>
									<input type="text" className="form-control" id="cemail" value={row.email} onChange={emailChange} />
								</div>
								<div className="col-sm-6">
									<label htmlFor="abc111" className="form-label">
										Phone
									</label>
									<input type="text" className="form-control" id="cphone" value={row.phone} onChange={phoneChange} />
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<button
						onClick={() => {
							setIseditmodal(false);
						}}
						type="button"
						className="btn btn-secondary"
						data-bs-dismiss="modal">
						Done
					</button>
					<button onClick={editCustomer} type="submit" className="btn btn-primary">
						Save
					</button>
				</Modal.Footer>
			</Modal>

			<Modal className="modal fade show" id="expadd" show={ismodal} onHide={handleClose}>
				<Modal.Header className="modal-header">
					<h5 className="modal-title  fw-bold" id="expaddLabel">
						Add Customers
					</h5>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div className="deadline-form">
						<form>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="cname" className="form-label">
										Customers Name
									</label>
									<input type="text" className="form-control" id="cname" onChange={nameChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="ccname" className="form-label">
										Company Name
									</label>
									<input type="text" className="form-control" id="ccname" onChange={companyNameChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="ccountry" className="form-label">
										Country
									</label>
									<select className="form-control" id="ccountry" value={row.country} onChange={countryChange}>
										<option value="">Seleccione un país</option>
										<option value="AF">Afganistán</option>
										<option value="AX">Islas Áland</option>
										<option value="AL">Albania</option>
										<option value="DZ">Argelia</option>
										<option value="AS">Samoa Americana</option>
										<option value="AD">Andorra</option>
										<option value="AO">Angola</option>
										<option value="AI">Anguila</option>
										<option value="AQ">Antártida</option>
										<option value="AG">Antigua y Barbuda</option>
										<option value="AR">Argentina</option>
										<option value="AM">Armenia</option>
										<option value="AW">Aruba</option>
										<option value="AU">Australia</option>
										<option value="AT">Austria</option>
										<option value="AZ">Azerbaiyán</option>
										<option value="BS">Bahamas</option>
										<option value="BH">Baréin</option>
										<option value="BD">Bangladés</option>
										<option value="BB">Barbados</option>
										<option value="BY">Bielorrusia</option>
										<option value="BE">Bélgica</option>
										<option value="BZ">Belice</option>
										<option value="BJ">Benín</option>
										<option value="BM">Bermudas</option>
										<option value="BT">Bután</option>
										<option value="BO">Bolivia</option>
										<option value="BA">Bosnia y Herzegovina</option>
										<option value="BW">Botsuana</option>
										<option value="BV">Isla Bouvet</option>
										<option value="BR">Brasil</option>
										<option value="IO">Territorio Británico del Océano Índico</option>
										<option value="BN">Brunéi</option>
										<option value="BG">Bulgaria</option>
										<option value="BF">Burkina Faso</option>
										<option value="BI">Burundi</option>
										<option value="CV">Cabo Verde</option>
										<option value="KH">Camboya</option>
										<option value="CM">Camerún</option>
										<option value="CA">Canadá</option>
										<option value="KY">Islas Caimán</option>
										<option value="CF">República Centroafricana</option>
										<option value="TD">Chad</option>
										<option value="CL">Chile</option>
										<option value="CN">China</option>
										<option value="CX">Isla de Navidad</option>
										<option value="CC">Islas Cocos</option>
										<option value="CO">Colombia</option>
										<option value="KM">Comoras</option>
										<option value="CG">Congo</option>
										<option value="CD">Congo (Rep. Dem.)</option>
										<option value="CK">Islas Cook</option>
										<option value="CR">Costa Rica</option>
										<option value="CI">Costa de Marfil</option>
										<option value="HR">Croacia</option>
										<option value="CU">Cuba</option>
										<option value="CW">Curazao</option>
										<option value="CY">Chipre</option>
										<option value="CZ">Chequia</option>
										<option value="DK">Dinamarca</option>
										<option value="DJ">Yibuti</option>
										<option value="DM">Dominica</option>
										<option value="DO">República Dominicana</option>
										<option value="EC">Ecuador</option>
										<option value="EG">Egipto</option>
										<option value="SV">El Salvador</option>
										<option value="AE">Emiratos Árabes Unidos</option>
										<option value="ER">Eritrea</option>
										<option value="SK">Eslovaquia</option>
										<option value="SI">Eslovenia</option>
										<option value="ES">España</option>
										<option value="US">Estados Unidos</option>
										<option value="EE">Estonia</option>
										<option value="ET">Etiopía</option>
										<option value="FK">Islas Malvinas</option>
										<option value="FO">Islas Feroe</option>
										<option value="FJ">Fiyi</option>
										<option value="FI">Finlandia</option>
										<option value="FR">Francia</option>
										<option value="GA">Gabón</option>
										<option value="GM">Gambia</option>
										<option value="GE">Georgia</option>
										<option value="GH">Ghana</option>
										<option value="GI">Gibraltar</option>
										<option value="GR">Grecia</option>
										<option value="GL">Groenlandia</option>
										<option value="GD">Granada</option>
										<option value="GP">Guadalupe</option>
										<option value="GU">Guam</option>
										<option value="GT">Guatemala</option>
										<option value="GG">Guernsey</option>
										<option value="GN">Guinea</option>
										<option value="GW">Guinea-Bisáu</option>
										<option value="GY">Guyana</option>
										<option value="HT">Haití</option>
										<option value="HM">Islas Heard y McDonald</option>
										<option value="HN">Honduras</option>
										<option value="HK">Hong Kong</option>
										<option value="HU">Hungría</option>
										<option value="IS">Islandia</option>
										<option value="IN">India</option>
										<option value="ID">Indonesia</option>
										<option value="IR">Irán</option>
										<option value="IQ">Irak</option>
										<option value="IE">Irlanda</option>
										<option value="IM">Isla de Man</option>
										<option value="IL">Israel</option>
										<option value="IT">Italia</option>
										<option value="JM">Jamaica</option>
										<option value="JP">Japón</option>
										<option value="JE">Jersey</option>
										<option value="JO">Jordania</option>
										<option value="KZ">Kazajistán</option>
										<option value="KE">Kenia</option>
										<option value="KI">Kiribati</option>
										<option value="KW">Kuwait</option>
										<option value="KG">Kirguistán</option>
										<option value="LA">Laos</option>
										<option value="LV">Letonia</option>
										<option value="LB">Líbano</option>
										<option value="LS">Lesoto</option>
										<option value="LR">Liberia</option>
										<option value="LY">Libia</option>
										<option value="LI">Liechtenstein</option>
										<option value="LT">Lituania</option>
										<option value="LU">Luxemburgo</option>
										<option value="MO">Macao</option>
										<option value="MG">Madagascar</option>
										<option value="MW">Malaui</option>
										<option value="MY">Malasia</option>
										<option value="MV">Maldivas</option>
										<option value="ML">Malí</option>
										<option value="MT">Malta</option>
										<option value="MH">Islas Marshall</option>
										<option value="MQ">Martinica</option>
										<option value="MR">Mauritania</option>
										<option value="MU">Mauricio</option>
										<option value="YT">Mayotte</option>
										<option value="MX">México</option>
										<option value="FM">Micronesia</option>
										<option value="MD">Moldavia</option>
										<option value="MC">Mónaco</option>
										<option value="MN">Mongolia</option>
										<option value="ME">Montenegro</option>
										<option value="MS">Montserrat</option>
										<option value="MA">Marruecos</option>
										<option value="MZ">Mozambique</option>
										<option value="MM">Myanmar (Birmania)</option>
										<option value="NA">Namibia</option>
										<option value="NR">Nauru</option>
										<option value="NP">Nepal</option>
										<option value="NL">Países Bajos</option>
										<option value="NC">Nueva Caledonia</option>
										<option value="NZ">Nueva Zelanda</option>
										<option value="NI">Nicaragua</option>
										<option value="NE">Níger</option>
										<option value="NG">Nigeria</option>
										<option value="NU">Niue</option>
										<option value="NF">Isla Norfolk</option>
										<option value="KP">Corea del Norte</option>
										<option value="MP">Islas Marianas del Norte</option>
										<option value="NO">Noruega</option>
										<option value="OM">Omán</option>
										<option value="PK">Pakistán</option>
										<option value="PW">Palaos</option>
										<option value="PS">Palestina</option>
										<option value="PA">Panamá</option>
										<option value="PG">Papúa Nueva Guinea</option>
										<option value="PY">Paraguay</option>
										<option value="PE">Perú</option>
										<option value="PH">Filipinas</option>
										<option value="PN">Islas Pitcairn</option>
										<option value="PL">Polonia</option>
										<option value="PT">Portugal</option>
										<option value="PR">Puerto Rico</option>
										<option value="QA">Catar</option>
										<option value="RE">Reunión</option>
										<option value="RO">Rumanía</option>
										<option value="RU">Rusia</option>
										<option value="RW">Ruanda</option>
										<option value="BL">San Bartolomé</option>
										<option value="SH">Santa Elena, Ascensión y Tristán de Acuña</option>
										<option value="KN">San Cristóbal y Nieves</option>
										<option value="LC">Santa Lucía</option>
										<option value="MF">San Martín (parte francesa)</option>
										<option value="PM">San Pedro y Miquelón</option>
										<option value="VC">San Vicente y las Granadinas</option>
										<option value="WS">Samoa</option>
										<option value="SM">San Marino</option>
										<option value="ST">Santo Tomé y Príncipe</option>
										<option value="SA">Arabia Saudita</option>
										<option value="SN">Senegal</option>
										<option value="RS">Serbia</option>
										<option value="SC">Seychelles</option>
										<option value="SL">Sierra Leona</option>
										<option value="SG">Singapur</option>
										<option value="SX">San Martín (parte holandesa)</option>
										<option value="SK">Eslovaquia</option>
										<option value="SI">Eslovenia</option>
										<option value="SB">Islas Salomón</option>
										<option value="SO">Somalia</option>
										<option value="ZA">Sudáfrica</option>
										<option value="GS">Islas Georgia del Sur y Sandwich del Sur</option>
										<option value="KR">Corea del Sur</option>
										<option value="SS">Sudán del Sur</option>
										<option value="ES">España</option>
										<option value="LK">Sri Lanka</option>
										<option value="SD">Sudán</option>
										<option value="SR">Surinam</option>
										<option value="SJ">Svalbard y Jan Mayen</option>
										<option value="SZ">Suazilandia</option>
										<option value="SE">Suecia</option>
										<option value="CH">Suiza</option>
										<option value="SY">Siria</option>
										<option value="TW">Taiwán</option>
										<option value="TJ">Tayikistán</option>
										<option value="TZ">Tanzania</option>
										<option value="TH">Tailandia</option>
										<option value="TL">Timor-Leste</option>
										<option value="TG">Togo</option>
										<option value="TK">Tokelau</option>
										<option value="TO">Tonga</option>
										<option value="TT">Trinidad y Tobago</option>
										<option value="TN">Túnez</option>
										<option value="TR">Turquía</option>
										<option value="TM">Turkmenistán</option>
										<option value="TC">Islas Turcas y Caicos</option>
										<option value="TV">Tuvalu</option>
										<option value="UG">Uganda</option>
										<option value="UA">Ucrania</option>
										<option value="AE">Emiratos Árabes Unidos</option>
										<option value="GB">Reino Unido</option>
										<option value="US">Estados Unidos</option>
										<option value="UM">Islas menores alejadas de los Estados Unidos</option>
										<option value="UY">Uruguay</option>
										<option value="UZ">Uzbekistán</option>
										<option value="VU">Vanuatu</option>
										<option value="VA">Ciudad del Vaticano</option>
										<option value="VE">Venezuela</option>
										<option value="VN">Vietnam</option>
										<option value="VG">Islas Vírgenes Británicas</option>
										<option value="VI">Islas Vírgenes de los Estados Unidos</option>
										<option value="WF">Wallis y Futuna</option>
										<option value="EH">Sahara Occidental</option>
										<option value="YE">Yemen</option>
										<option value="ZM">Zambia</option>
										<option value="ZW">Zimbabue</option>
									</select>
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										City
									</label>
									<input type="text" className="form-control" id="ccity" onChange={cityChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="cstate" className="form-label">
										State
									</label>
									{row.country !== "US" ? (
										<input type="text" className="form-control" id="cstate" onChange={stateChange} />
									) : (
										<select className="form-control" id="cstate" value={row.state} onChange={stateChange}>
											<option value="">Select a state</option>
											<option value="AL">Alabama</option>
											<option value="AK">Alaska</option>
											<option value="AZ">Arizona</option>
											<option value="AR">Arkansas</option>
											<option value="CA">California</option>
											<option value="CO">Colorado</option>
											<option value="CT">Connecticut</option>
											<option value="DE">Delaware</option>
											<option value="FL">Florida</option>
											<option value="GA">Georgia</option>
											<option value="HI">Hawaii</option>
											<option value="ID">Idaho</option>
											<option value="IL">Illinois</option>
											<option value="IN">Indiana</option>
											<option value="IA">Iowa</option>
											<option value="KS">Kansas</option>
											<option value="KY">Kentucky</option>
											<option value="LA">Louisiana</option>
											<option value="ME">Maine</option>
											<option value="MD">Maryland</option>
											<option value="MA">Massachusetts</option>
											<option value="MI">Michigan</option>
											<option value="MN">Minnesota</option>
											<option value="MS">Mississippi</option>
											<option value="MO">Missouri</option>
											<option value="MT">Montana</option>
											<option value="NE">Nebraska</option>
											<option value="NV">Nevada</option>
											<option value="NH">New Hampshire</option>
											<option value="NJ">New Jersey</option>
											<option value="NM">New Mexico</option>
											<option value="NY">New York</option>
											<option value="NC">North Carolina</option>
											<option value="ND">North Dakota</option>
											<option value="OH">Ohio</option>
											<option value="OK">Oklahoma</option>
											<option value="OR">Oregon</option>
											<option value="PA">Pennsylvania</option>
											<option value="RI">Rhode Island</option>
											<option value="SC">South Carolina</option>
											<option value="SD">South Dakota</option>
											<option value="TN">Tennessee</option>
											<option value="TX">Texas</option>
											<option value="UT">Utah</option>
											<option value="VT">Vermont</option>
											<option value="VA">Virginia</option>
											<option value="WA">Washington</option>
											<option value="WV">West Virginia</option>
											<option value="WI">Wisconsin</option>
											<option value="WY">Wyoming</option>
										</select>
									)}
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										Post Code
									</label>
									<input type="text" className="form-control" id="cpost" onChange={postcodeChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="depone" className="form-label">
										Address
									</label>
									<input type="text" className="form-control" id="caddress" onChange={addressChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="abc11" className="form-label">
										E-mail
									</label>
									<input type="text" className="form-control" id="cemail" onChange={emailChange} />
								</div>
								<div className="col-sm-6">
									<label htmlFor="abc111" className="form-label">
										Phone
									</label>
									<input type="text" className="form-control" id="cphone" onChange={phoneChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="abc11" className="form-label">
										Password
									</label>
									<input type="text" className="form-control" id="cpass" onChange={passwordChange} />
								</div>
								<div className="col-sm-6"></div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<button onClick={handleClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
						Done
					</button>
					<button onClick={addCustomer} type="submit" className="btn btn-primary">
						Add
					</button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={iscardmodal}
				onHide={() => {
					setIscardmodal(false);
				}}
				className=""
				style={{ display: "block" }}>
				<Modal.Header className="modal-header" closeButton>
					<h5 className="modal-title  fw-bold" id="expeditLabel">
						{" "}
						Customer Card
					</h5>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div className="deadline-form">
						<form>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="item" className="form-label">
										Customers Name
									</label>
									<input type="text" className="form-control" value={row.name} onChange={nameChange} disabled={true} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="depone" className="form-label">
										Card Name
									</label>
									<input type="text" className="form-control" value={card.cardName} onChange={cardNameChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="depone" className="form-label">
										Card Number
									</label>
									<input type="text" className="form-control" value={card.cardNumber} onChange={cardNumberChange} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										Expiration Date
									</label>
									<input type="month" id="depone" className="form-control" value={card.exp} onChange={expDateChange} />
								</div>

								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										CCV
									</label>
									<input type="text" className="form-control" value={card.ccv} onChange={ccvChange} />
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<button
						onClick={() => {
							setIscardmodal(false);
						}}
						type="button"
						className="btn btn-secondary"
						data-bs-dismiss="modal">
						Done
					</button>
					<button onClick={addCard} type="submit" className="btn btn-primary">
						Save
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
export default CustomerList;
