import React from 'react';
import Dashboard from './Dashboard/Dashboard';
import ProductGrid from './Products/ProductGrid';
import ProductList from './Products/ProductList';
import { Switch, Route } from 'react-router-dom';
import ProductEdit from './Products/ProductEdit';
import ProductDetail from './Products/ProductDetail';
import ProductAdd from './Products/ProductAdd';
import ShoppingCart from './Products/ShoppingCart';
import Header from '../components/common/Header';
import CheckOut from './Products/CheckOut';
import CategoriesList from './Categories/CategoriesList';
import OrderList from './Orders/OrderList';
import OrderDetail from './Orders/OrderDetail';
import OrderInvoice from './Orders/OrderInvoice';
import CustomerList from './Customers/CustomerList';
import CustomerDetail from './Customers/CustomerDetail';
import CouponsList from './SalesPromotion.js/CouponsList';
import CouponsAdd from './SalesPromotion.js/CouponsAdd';
import CouponsEdit from './SalesPromotion.js/CouponsEdit';
import StockList from './Inventory/StockList';
import Purchase from './Inventory/Purchase';
import Supplier from './Inventory/Supplier';
import Return from './Inventory/Return';
import Departments from './Inventory/Departments';
import Invoices from './Accounts/Invoices';
import Expense from './Accounts/Expense';
import Salaryslip from './Accounts/Salaryslip';
import Chat from './App/Chat';
import ProfilePage from './Other Pages/ProfilePage'
import PricePlanExample from './Other Pages/PricePlanExample';
import ContactUs from './Other Pages/ContactUs';
import Icons from './Other Pages/Icon';
import FormsExample from './Other Pages/FormsExample';
import TableExample from './Other Pages/TableExample';
import ChartsExample from './Other Pages/ChartsExample';
import Alerts from './Uicomponent/Alerts';
import Badges from './Uicomponent/Badge';
import Breadcrumb from './Uicomponent/Breadcrumb';
import Buttons from './Uicomponent/Buttons';
import Cards from './Uicomponent/Card';
import Carousel from './Uicomponent/Carousel';
import Collapse from './Uicomponent/Collapse';
import Dropdowns from './Uicomponent/Dropdowns';
import ListGroup from './Uicomponent/ListGroup';
import ModalUI from './Uicomponent/Modal';
import NavbarUI from './Uicomponent/Navbar';
import NavsUI from './Uicomponent/Navs';
import PaginationUI from './Uicomponent/Pagination';
import PopoversUI from './Uicomponent/Popovers';
import ProgressUI from './Uicomponent/Progress';
import Scrollspy from './Uicomponent/Scrollspy';
import SpinnersUI from './Uicomponent/Spinners';
import ToastsUI from './Uicomponent/Toasts';
import Calendar from './App/Calendar';
import StaterPage from './Stater Page/StaterPage';
import Documentation from './Documentation/Documentation';
import Changelog from './Changelog/Changelog';
import CategoriesEdit from './Categories/CategoriesEdit';
import CategoriesAdd from './Categories/CategoriesAdd';
import StoreLocation from './StoreLocation/Storelocation';
import Help from './Help/Help';
import SimpleInvoice from '../components/Accounts/Invoice/SimpleInvoice';

function MainIndex(props) {
    const { activekey } = props;
    return (
        <div className='main px-lg-4 px-md-4' >
            {activekey === "/chat" ? "" : <Header />}
            <div className="body d-flex py-3 ">
                <Switch>
                    <Route exact path={"/"} render={() => { return <Dashboard /> }} />
                    <Route exact path={"/dashboard"} render={() => { return <Dashboard /> }} />
                    <Route exact path={'/product-list'} render={() => { return <ProductList /> }} />
                    <Route exact path={'/product-Add'} render={() => { return <ProductAdd /> }} />
                    <Route exact path={'/product-edit'} render={() => { return <ProductEdit /> }} />
                    <Route exact path={'/categories-list'} render={() => { return <CategoriesList /> }} />
                    <Route exact path={'/categories-add'} render={() => { return <CategoriesAdd /> }} />
                    <Route exact path={'/categories-edit'} render={() => { return <CategoriesEdit /> }} />
                    <Route exact path={'/shopping-cart'} render={() => { return <ShoppingCart /> }} />
                    <Route exact path={'/check-out'} render={() => { return <CheckOut /> }} />
                    <Route exact path={'/order-list'} render={() => { return <OrderList /> }} />
                    <Route exact path={'/order-detail'} render={() => { return <OrderDetail /> }} />
                    <Route exact path={'/order-invoice'} render={() => { return <OrderInvoice /> }} />
                    <Route exact path={'/customer-list'} render={() => { return <CustomerList /> }} />
                    <Route exact path={'/customer-detail'} render={() => { return <CustomerDetail /> }} />
                    <Route exact path={'/coupons-list'} render={() => { return <CouponsList /> }} />
                    <Route exact path={'/coupons-add'} render={() => { return <CouponsAdd /> }} />
                    <Route exact path={'/coupons-edit'} render={() => { return <CouponsEdit /> }} />
                    <Route exact path={'/simple-invoice'} render={() => { return <SimpleInvoice /> }} />



                    <Route exact path={'/stock-list'} render={() => { return <StockList /> }} />
                    <Route exact path={'/product-grid'} render={() => { return <ProductGrid /> }} />
                    <Route exact path={'/product-detail'} render={() => { return <ProductDetail /> }} />
                    <Route exact path={'/purchase'} render={() => { return <Purchase /> }} />
                    <Route exact path={'/supplier'} render={() => { return <Supplier /> }} />
                    <Route exact path={'/return'} render={() => { return <Return /> }} />
                    <Route exact path={'/departments'} render={() => { return <Departments /> }} />
                    <Route exact path={'/invoices'} render={() => { return <Invoices /> }} />

                    <Route exact path={'/expense'} render={() => { return <Expense /> }} />
                    <Route exact path={'/salaryslip'} render={() => { return <Salaryslip /> }} />
                    <Route exact path={'/chat'} render={() => { return <Chat /> }} />
                    <Route exact path={'/calendar'} render={() => { return <Calendar /> }} />
                    <Route exact path={'/store-location'} render={() => { return <StoreLocation /> }} />

                    <Route exact path={'/profile-pages'} render={() => { return <ProfilePage /> }} />
                    <Route exact path={'/price-plan'} render={() => { return <PricePlanExample /> }} />
                    <Route exact path={'/contact-us'} render={() => { return <ContactUs /> }} />
                    <Route exact path={'/icons'} render={() => { return <Icons /> }} />
                    <Route exact path={'/form-example'} render={() => { return <FormsExample /> }} />
                    <Route exact path={'/table-example'} render={() => { return <TableExample /> }} />
                    <Route exact path={'/charts-example'} render={() => { return <ChartsExample /> }} />

                    <Route exact path={'/ui-alerts'} render={() => { return <Alerts /> }} />
                    <Route exact path={'/ui-badge'} render={() => { return <Badges /> }} />
                    <Route exact path={'/ui-breadcrumb'} render={() => { return <Breadcrumb /> }} />
                    <Route exact path={'/ui-buttons'} render={() => { return <Buttons /> }} />
                    <Route exact path={'/ui-card'} render={() => { return <Cards /> }} />
                    <Route exact path={'/ui-carousel'} render={() => { return <Carousel /> }} />
                    <Route exact path={'/ui-collapse'} render={() => { return <Collapse /> }} />
                    <Route exact path={'/ui-dropdowns'} render={() => { return <Dropdowns /> }} />
                    <Route exact path={'/ui-listgroup'} render={() => { return <ListGroup /> }} />
                    <Route exact path={'/ui-modalui'} render={() => { return <ModalUI /> }} />
                    <Route exact path={'/ui-navbarui'} render={() => { return <NavbarUI /> }} />
                    <Route exact path={'/ui-navsui'} render={() => { return <NavsUI /> }} />
                    <Route exact path={'/ui-paginationui'} render={() => { return <PaginationUI /> }} />
                    <Route exact path={'/ui-popoversui'} render={() => { return <PopoversUI /> }} />
                    <Route exact path={'/ui-progressui'} render={() => { return <ProgressUI /> }} />
                    <Route exact path={'/ui-Scrollspyui'} render={() => { return <Scrollspy /> }} />
                    <Route exact path={'/ui-spinnersui'} render={() => { return <SpinnersUI /> }} />
                    <Route exact path={'/ui-toastsui'} render={() => { return <ToastsUI /> }} />
                    <Route exact path={'/stater-page'} render={() => { return <StaterPage /> }} />
                    <Route exact path={'/documentation'} render={() => { return <Documentation /> }} />
                    <Route exact path={'/changelog'} render={() => { return <Changelog /> }} />

                    <Route exact path={'/help'} render={() => { return <Help /> }} />

                </Switch>
            </div>
        </div>
    );
}
export default MainIndex;