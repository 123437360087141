import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import AddModal from './components/common/AddModal';
import Sidebar from './components/common/Sidebar';
import MainIndex from './screens/MainIndex';
import SignIn from './components/Auth/SignIn';
import AuthLeft from './components/Auth/AuthLeft';


function App(props) {
    const activekey = () => {
        var res = window.location.pathname
        var baseUrl = process.env.PUBLIC_URL
        // baseUrl = baseUrl.split("/");
        res = res.split("/");
        res = res.length > 0 ? res[baseUrl.length] : "/";
        res = res ? "/" + res : "/";;
        const activeKey1 = res;
        return activeKey1
    }
    localStorage.setItem('user', "TESTING");
    const loggedInUser = localStorage.getItem("user");
    // console.log("LOGGED", loggedInUser);

    if (loggedInUser === null) {
        return (
            <div className='main p-2 py-3 p-xl-5 '>
                <div className='body d-flex p-0 p-xl-5'>
                    <div className='container-xxl'>
                        <div className='row g-0'>
                            <AuthLeft />
                            <SignIn />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div id="ebazar-layout" className='theme-blue'>
                <Sidebar activekey={activekey()} history={props.history} />
                <AddModal />
                <Switch>
                    <MainIndex activekey={activekey()} />
                </Switch>
            </div>
        )
    }
}
export default withRouter(App);