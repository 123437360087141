import { elementMatches } from "@fullcalendar/react";
import axios from "axios";
const baseURL = "https://mitserver.app:1515";

export const OnchangeAddimage = (e) => (dispatch) => {

    if (e[0].size < (1 * 1024 * 1024)) {
        dispatch({
            type: 'ADD_IMAGE',
            payload: e
        })
    }
    else {
        dispatch({
            type: 'IMAGE_VALIDATION'
        })

    }
}

export const Onchangeusername = (e) => (dispatch) => {
    dispatch({
        type: 'USER_NAME',
        payload: e
    })
}

export const onChangeDarkMode = (val) => (dispatch) => {

    if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'dark');
    } else if (val === 'high-contrast') {
        //window.document.children[0].setAttribute('data-theme', 'light')
    } else {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
}
export const onChangeHighcontrast = (val) => (dispatch) => {

    if (val === 'high-contrast') {
        window.document.children[0].setAttribute('data-theme', 'high-contrast');
    } else if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    else {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
}
export const OnchangeRTLmode = (val) => (dispatch) => {

    if (document.body.classList.contains("rtl_mode")) {
        document.body.classList.remove("rtl_mode")
    } else {
        document.body.classList.add("rtl_mode");
    }

    dispatch({
        type: 'rtl_mode',
        payload: val
    })
}
export const Onopenmodalsetting = (val) => (dispatch) => {

    dispatch({
        type: 'OPEN_MODAL',
        payload: val
    })
}
export const OnGradientColor = (val) => (dispatch) => {
    var theme = document.getElementById("mainsidemenu");
    if (theme) {
        if (!theme.classList.contains('gradient')) {
            theme.classList.add('gradient');
            dispatch({
                type: 'GRADIENT_COLOR',
                payload: true
            })
        }
        else {
            theme.classList.remove('gradient');
            dispatch({
                type: 'GRADIENT_COLOR',
                payload: false
            })
        }
    }

}

export async function getProducts(dispatch) {
    const response = await axios.post(baseURL + "/getLists", {
        list: 'products'
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;

    // var rows = [];
    // function renderSwitch(cat) {
    //     switch (cat) {
    //         case 1:
    //             return 'Equipment';
    //         case 2:
    //             return 'Resins';
    //         case 3:
    //             return 'Kits';
    //         default:
    //             return 'foo';
    //     }
    // };
    // data.forEach(element => {
    //     var cat = element.category;
    //     var category = renderSwitch(cat);
    //     var row = {
    //         id: element.id,
    //         code: element.code,
    //         name: element.name,
    //         description: element.description,
    //         category: category,
    //         price: element.price,
    //         image: element.image,
    //         stock: element.stock,
    //         status: element.status
    //     };
    //     rows.push(row);
    // });
    dispatch({
        type: 'GET_PRODUCTS',
        payload: data
    });
}

export async function getCategories(dispatch) {
    const response = await axios.post(baseURL + "/getLists", {
        list: 'categories'
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;

    dispatch({
        type: 'GET_CATEGORIES',
        payload: data
    });
}

export async function getCustomers(dispatch) {
    const response = await axios.post(baseURL + "/getLists", {
        list: 'customers'
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;

    dispatch({
        type: 'GET_CUSTOMERS',
        payload: data
    });
}

export async function getOrders(dispatch) {
    const response = await axios.post(baseURL + "/getLists", {
        list: 'orders'
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;

    dispatch({
        type: 'GET_ORDERS',
        payload: data
    });
}

export const getCustomer = (val) => async (dispatch) => {
    const response = await axios.post(baseURL + "/getCustomer", {
        id: val
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;
    localStorage.setItem('customer', JSON.stringify(data[0]));
    console.log('CUSTOMER', data[0]);
    dispatch({
        type: 'GET_CUSTOMER',
        payload: data[0]
    });
}

export const getCustomerByName = (val) => async (dispatch) => {
    if (val !== '') {
        const response =
            await axios.post(baseURL + "/getCustomerByName", {
                name: val
            })
        var resp = response.data;
        var result = resp.result;
        var data = result.data;
        console.log("CUSTOMER SEARCH", data);
        dispatch({
            type: 'GET_CUSTOMER',
            payload: data
        });
    } else {
        dispatch({
            type: 'GET_CUSTOMER',
            payload: []
        });
    }
}

export const getOrderItems = (val) => async (dispatch) => {
    const response = await axios.post(baseURL + "/getOrderItems", {
        id: val
    })
    var resp = response.data;
    var result = resp.result;
    var data = result.data;
    localStorage.setItem('orderItems', JSON.stringify(data));
    dispatch({
        type: 'GET_ORDERITEMS',
        payload: data
    });
}

export async function getDashboard(dispatch) {
    const response = await axios.post(baseURL + "/getDashboard")
    var resp = response.data;
    var result = resp.result;
    var data = result.data;
    // localStorage.setItem('getDashboard', JSON.stringify(data));
    console.log("DASHBOARED", data);

    dispatch({
        type: 'GET_DASHBOARD',
        payload: data.dashboard
    });
    dispatch({
        type: 'GET_ORDERS',
        payload: data.orders
    });
    dispatch({
        type: 'GET_CUSTOMERS',
        payload: data.customers
    });
    dispatch({
        type: 'GET_PRODUCTS',
        payload: data.products
    });
    dispatch({
        type: 'GET_CATEGORIES',
        payload: data.categories
    });
}

export const getProductByName = (val, customerID) => async (dispatch) => {
    if (val !== '') {
        const response =
            await axios.post(baseURL + "/getProductByName", {
                name: val,
                customerID
            })
        var resp = response.data;
        var result = resp.result;
        var data = result.data;
        console.log("PRODUCT SEARCH", data);
        dispatch({
            type: 'GET_PRODUCT',
            payload: data
        });
    } else {
        dispatch({
            type: 'GET_PRODUCT',
            payload: []
        });
    }
}