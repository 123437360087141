import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../Redux/Actions/Action";
import { getCustomer, getOrderItems } from "../../Redux/Actions/Action";
import { Modal } from "react-bootstrap";

import PageHeader1 from "../../components/common/PageHeader1";

function OrderList() {
	let history = useHistory();
	const dispatch = useDispatch();
	const orders = useSelector((state) => state.Mainreducer.orders);
	const [addModal, setAddModal] = useState(false);
	const [data, setData] = useState();

	const columns = [
		{
			name: "ORDER ID",
			selector: (row) => row.id,
			sortable: true,
			cell: (row) => (
				<Link to={"/order-detail"} className="">
					{row.id}
				</Link>
			),
			minWidth: "0px",
		},
		{
			name: "DATE",
			selector: (row) => row.createdDate.split("GMT")[0],
			// cell: row => <><img className="avatar rounded lg border" src={row.image} alt="" /> <span className="px-2">{row.name}</span></>,
			sortable: true,
			minWidth: "200px",
		},
		{
			name: "CUSTOMERS NAME",
			selector: (row) => row.customerName,
			sortable: true,
			minWidth: "220px",
		},
		{
			name: "PAYMENT INFO",
			selector: (row) => row.orderID,
			sortable: true,
			minWidth: "150px",
		},
		{
			name: "SHIPPING METHOD",
			selector: (row) => row.shippingMethod,
			sortable: true,
			minWidth: "170px",
		},
		{
			name: "SHIPPING",
			selector: (row) => "$ " + row.shippingAmount,
			sortable: true,
			minWidth: "100px",
		},
		{
			name: "TOTAL",
			selector: (row) => "$ " + row.totalAmount,
			sortable: true,
			minWidth: "100px",
		},
		{
			name: "STATUS",
			selector: (row) => row.status,
			sortable: true,
			cell: (row) => <span className={`badge ${row.status === "Complited" ? "bg-success" : "bg-warning"}`}>{row.status}</span>,
		},
	];

	useEffect(() => {
		console.log("DISPATCH ORDERS");
		// if (orders.length === 0) {
		dispatch(getOrders);
		// }
	}, []);

	const rowClick = async (order) => {
		// await localStorage.clear('customer');
		localStorage.setItem("order", JSON.stringify(order));
		dispatch(getCustomer(order.customerID));
		dispatch(getOrderItems(order.id));
		history.push("/order-detail");
	};

	function handleOnChange(evt) {
		const value = evt.target.value;
		setData({
			...data,
			[evt.target.name]: value,
		});
	}

	return (
		<div className="body d-flex py-3">
			<div className="container-xxl">
				<PageHeader1
					pagetitle="Order List"
					modalbutton={() => {
						return (
							<div className="col-auto d-flex w-sm-100">
								<button
									type="button"
									onClick={() => history.push("/simple-invoice")}
									className="btn btn-primary btn-set-task w-sm-100"
									data-bs-toggle="modal"
									data-bs-target="#expadd"
								>
									<i className="icofont-plus-circle me-2 fs-6"></i>Add OffLine Order
								</button>
							</div>
						);
					}}
				/>
				<div className="row g-3 mb-3">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
									<div className="row">
										<div className="col-sm-12">
											<DataTable
												columns={columns}
												data={orders}
												defaultSortField="title"
												pagination
												selectableRows={false}
												className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
												highlightOnHover={true}
												pointerOnHover={true}
												onRowClicked={rowClick}
												striped={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal className="modal fade show" id="expadd" show={addModal} onHide={() => setAddModal(false)}>
				<Modal.Header className="modal-header">
					<h5 className="modal-title  fw-bold" id="expaddLabel">
						Add Customers
					</h5>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div className="deadline-form">
						<form>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="item" className="form-label">
										Customers Name
									</label>
									<input type="text" className="form-control" id="cname" onChange={(event) => handleOnChange(event)} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										Country
									</label>
									<input type="text" className="form-control" id="ccountry" onChange={(event) => handleOnChange(event)} />
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										City
									</label>
									<input type="text" className="form-control" id="ccity" onChange={(event) => handleOnChange(event)} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										State
									</label>
									<input type="text" className="form-control" id="cstate" onChange={(event) => handleOnChange(event)} />
								</div>
								<div className="col-sm-6">
									<label htmlFor="depone" className="form-label">
										Post Code
									</label>
									<input type="text" className="form-control" id="cpost" onChange={(event) => handleOnChange(event)} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-12">
									<label htmlFor="depone" className="form-label">
										Address
									</label>
									<input type="text" className="form-control" id="caddress" onChange={(event) => handleOnChange(event)} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="abc11" className="form-label">
										E-mail
									</label>
									<input type="text" className="form-control" id="cemail" onChange={(event) => handleOnChange(event)} />
								</div>
								<div className="col-sm-6">
									<label htmlFor="abc111" className="form-label">
										Phone
									</label>
									<input type="text" className="form-control" id="cphone" onChange={(event) => handleOnChange(event)} />
								</div>
							</div>
							<div className="row g-3 mb-3">
								<div className="col-sm-6">
									<label htmlFor="abc11" className="form-label">
										Password
									</label>
									<input type="text" className="form-control" id="cpass" onChange={(event) => handleOnChange(event)} />
								</div>
								<div className="col-sm-6"></div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<button onClick={() => setAddModal(false)} type="button" className="btn btn-secondary" data-bs-dismiss="modal">
						Done
					</button>
					<button onClick={{}} type="submit" className="btn btn-primary">
						Add
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
export default OrderList;
