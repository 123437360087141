import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getCategories } from '../../Redux/Actions/Action';
import axios from "axios";
const baseURL = "https://mitserver.app:1515";
const imgURL = 'https://poly-lite.com/images/product-catalog/';

function CategoriesAdd() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [row, setRow] = useState([]);
    const [supplyopen, setSupplyopen] = useState(false)

    // useEffect(() => {
    //     var row = location.state;
    //     console.log("ROW", row);
    //     setRow(row);
    // }, [location]);

    async function addCategory() {
        const response = await axios.post(baseURL + "/actions", {
            action: 'add',
            table: 'categories',
            data: row
        })
        var data = response.data;
        var result = data.result;
        console.log("RESP", result);
        dispatch(getCategories);

        toast.success(result.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
        history.push({
            pathname: "/categories-list"
        });
    }

    function nameChange(e) {
        setRow({
            ...row,
            name: e.target.value
        });
    };

    function codeChange(e) {
        setRow({
            ...row,
            code: e.target.value
        });
    };

    function statusChange(e) {
        console.log("STATUS", e.target.value)
        setRow({
            ...row,
            status: e.target.value
        });
    }

    return (
        <div className="container-xxl">
            <div className="row align-items-center">
                <div className="border-0 mb-4">
                    <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                        <h3 className="fw-bold mb-0">Category Add</h3>
                        <button type="submit" className="btn btn-primary btn-set-task w-sm-100 text-uppercase px-5" onClick={addCategory}>Save</button>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="col-xl-12 col-lg-12">
                    <div className="card mb-3">
                        <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                            <h6 className="mb-0 fw-bold ">Basic information</h6>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-6">
                                        <label className="form-label">Name</label>
                                        <input type="text" className="form-control" value={row.name} onChange={nameChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Code</label>
                                        <input type="text" className="form-control" value={row.code} onChange={codeChange} />
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                        <h6 className="m-0 fw-bold">Visibility Status</h6>
                                    </div>
                                    <div className="card-body" onChange={statusChange}>
                                        <div className="form-check" >
                                            <input className="form-check-input" checked={row.status === "Enable"} type="radio" name="status" value="Enable" />
                                            <label className="form-check-label">
                                                Enable
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" checked={row.status === "Disable"} type="radio" name="status" value="Disable" />
                                            <label className="form-check-label">
                                                Disable
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoriesAdd;