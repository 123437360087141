import React, { useEffect, useState } from 'react';
import { Tab, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getDashboard, getCustomer, getOrderItems } from '../../Redux/Actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

function Dashboard() {

    let history = useHistory();
    const dispatch = useDispatch();
    const dashboard = useSelector((state) => state.Mainreducer.dashboard);
    const orders = useSelector((state) => state.Mainreducer.orders);
    const [orderLength, setOrderLength] = useState('');
    const [data, setData] = useState();

    const DashboardStatusData = [
        {
            title: 'Month Sales',
            value: dashboard.monthSales,
            iconClass: 'fa fa-dollar fa-lg',
            bgClass: 'alert-success',
            iconBgClass: 'bg-success'
        },
        {
            title: 'Month Orders',
            value: dashboard.monthOrders,
            iconClass: 'fa fa-credit-card fa-lg',
            bgClass: 'alert-danger',
            iconBgClass: 'bg-danger'
        },
        {
            title: 'Month Products Sold',
            value: dashboard.monthOrderItems,
            iconClass: 'fa fa-shopping-bag',
            bgClass: 'alert-info',
            iconBgClass: 'bg-info'
        },
        {
            title: 'Month Happy Clients',
            value: dashboard.monthCustomers,
            iconClass: 'fa fa-smile-o fa-lg',
            bgClass: 'alert-warning',
            iconBgClass: 'bg-warning'
        },
    ]

    const TodayData = [
        {
            title: 'Total Customers',
            value: dashboard.customers,
            iconClass: 'icofont-student-alt fs-3 color-light-orange',
            cardBg: 'bg-light'
        },
        {
            title: 'total Orders',
            value: dashboard.orders,
            iconClass: 'icofont-shopping-cart fs-3 color-lavender-purple',
            cardBg: 'bg-light'
        },
        {
            title: 'Avg Sale',
            value: '$1770',
            iconClass: 'icofont-sale-discount fs-3 color-santa-fe',
            cardBg: 'bg-light'
        },
        {
            title: 'Total Products Sold',
            value: dashboard.orderItems,
            iconClass: 'icofont-calculator-alt-2 fs-3 color-danger',
            cardBg: 'bg-light'
        },
        {
            title: 'Total Sale',
            value: dashboard.totalSales,
            iconClass: 'icofont-dollar fs-3 color-lightblue',
            cardBg: 'bg-light'
        },
        {
            title: 'Total Products',
            value: dashboard.products,
            iconClass: 'icofont-bag fs-3 color-light-orange',
            cardBg: 'bg-light'
        }
    ]

    const orderColumns = [
        {
            name: "ORDER ID",
            selector: (row) => row.id,
            sortable: true,
            cell: (row) => <Link to={"/order-detail"} className="">{row.id}</Link>,
            minWidth: '0px'
        },
        {
            name: "DATE",
            selector: (row) => row.createdDate.split('GMT')[0],
            // cell: row => <><img className="avatar rounded lg border" src={row.image} alt="" /> <span className="px-2">{row.name}</span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "CUSTOMERS NAME",
            selector: (row) => row.customerName,
            sortable: true,
            minWidth: "220px"
        },
        {
            name: "PAYMENT INFO",
            selector: (row) => row.orderID,
            sortable: true, minWidth: "150px"
        },
        {
            name: "SHIPPING METHOD",
            selector: (row) => row.shippingMethod,
            sortable: true, minWidth: "170px"
        },
        {
            name: "SHIPPING",
            selector: (row) => "$ " + row.shippingAmount,
            sortable: true, minWidth: "100px"
        },
        {
            name: "TOTAL",
            selector: (row) => "$ " + row.totalAmount,
            sortable: true, minWidth: "100px"
        },
        {
            name: "STATUS",
            selector: (row) => row.status,
            sortable: true,
            cell: row => <span className={`badge ${row.status === "Complited" ? 'bg-success' : "bg-warning"}`}>{row.status}</span>
        },

    ];

    useEffect(() => {
        console.log("DASH", dashboard);
        dispatch(getDashboard);
        // dispatch(getCategories);
        // dispatch(getProducts);
        // dispatch(getCustomers);
        // dispatch(getOrders);
    }, []);

    // useEffect(() => {
    //     const sumall = orders.map(item => Number(item.totalAmount)).reduce((prev, curr) => prev + curr, 0);

    //     var actualMonth = new Date().getMonth() + 1;
    //     var monthOrders = [];
    //     orders.forEach(o => {
    //         var created = o.createdDate;
    //         created = new Date(created).getMonth() + 1;
    //         if (actualMonth === created) {
    //             monthOrders.push(o);
    //         }
    //         console.log("DATE", created);
    //     });


    //     setData({
    //         ...data,
    //         totalOrders: sumall,
    //         monthOrders,
    //     });

    //     if (orders) {
    //         setOrderLength(orders.length);
    //         console.log("ORDERS", orders.length);
    //     }
    // }, [orders])

    const rowClick = (order) => {
        localStorage.setItem('order', JSON.stringify(order));
        dispatch(getCustomer(order.customerID));
        dispatch(getOrderItems(order.id));
        history.push("/order-detail");
    }

    return (
        <div className="body d-flex py-3">
            <div className="container-xxl">
                <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
                    {
                        DashboardStatusData.map((d, i) => {
                            return <div key={'statuscard' + i} className="col">
                                <div className={`${d.bgClass} alert mb-0`}>
                                    <div className="d-flex align-items-center">
                                        <div className={`avatar rounded no-thumbnail ${d.iconBgClass} text-light`}><i className={d.iconClass}></i></div>
                                        <div className="flex-fill ms-3 text-truncate">
                                            <div className="h6 mb-0">{d.title}</div>
                                            <span className="small">{d.value}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="mt-1">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="col-lg-12 col-md-12">
                        <Row>
                            <Col sm={12}>
                                <Tab.Content className="tab-content mt-1">
                                    <Tab.Pane eventKey="first" className="tab-pane fade show" id="summery-today">
                                        <div className="row g-1 g-sm-3 mb-3 row-deck">
                                            {
                                                TodayData.map((d, i) => {
                                                    return <div key={'todaydata' + i} className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                        <div className="card">
                                                            <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                                                                <div className="left-info">
                                                                    <span className="text-muted">{d.title}</span>
                                                                    <div><span className="fs-6 fw-bold me-2">{d.value}</span></div>
                                                                </div>
                                                                <div className="right-icon">
                                                                    <i className={`${d.iconClass}`}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                                <h6 className="m-0 fw-bold">Recent Orders</h6>
                            </div>
                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer" >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <DataTable
                                                title={'Ordenes Recientes'}
                                                columns={orderColumns}
                                                data={orders.slice(Math.max(orders.length - 5, 0))}
                                                defaultSortField="title"
                                                selectableRows={false}
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                onRowClicked={rowClick}
                                                striped={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Dashboard;