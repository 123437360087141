export const PricingData = [
    {
        color:'',
        name: 'Subotal Price:',
        price: '$1096.00'
    },
    {
        color:'shipping',
        name: 'Shipping Cost:',
        price: '$12.00'
    },
    {
        color:'discount',
        name: 'Discount Price:',
        price: '$10.00'
    },
    {
        color:'',
        name: 'Tax(18%):',
        price: '$198.00'
    },
    
]
    