const initialState = {
    username: '',
    darkMode: '',
    highcontrast: '',
    openmodal: false,
    openhelp: false,
    rtlmode: false,
    gradient: '',
    addimage: '',
    imgvalidation: '',
    products: [],
    categories: [],
    customers: [],
    customer: [],
    orders: [],
    ordersItems: [],
    dashboard: [],
    product: '',

}

const Mainreducer = (state = initialState, action) => {
    switch (action.type) {

        case 'USER_NAME': {

            return {
                ...state,
                username: action.payload
            }
        }
        case 'OPEN_MODAL': {

            return {
                ...state,
                openmodal: action.payload
            }
        }
        case 'SECOND': {

            return {
                ...state,
                second: action.payload
            }
        }
        case 'DARK_MODE': {

            return {
                ...state,
                darkMode: action.payload
            }
        }
        case 'HIGH_CONTRAST': {

            return {
                ...state,
                highcontrast: action.payload
            }
        }
        case 'GRADIENT_COLOR': {
            return {
                ...state,
                gradient: action.payload
            }
        }
        case 'ADD_IMAGE': {
            return {
                ...state,
                addimage: action.payload
            }

        }
        case 'IMAGE_VALIDATION': {
            return {
                ...state,
                imgvalidation: 'it is more then 10 mb'
            }

        }
        case 'GET_PRODUCTS': {
            return {
                ...state,
                products: action.payload
            }
        }
        case 'GET_CATEGORIES': {
            return {
                ...state,
                categories: action.payload
            }
        }
        case 'GET_CUSTOMERS': {
            return {
                ...state,
                customers: action.payload
            }
        }
        case 'GET_ORDERS': {
            return {
                ...state,
                orders: action.payload
            }
        }
        case 'GET_CUSTOMER': {
            return {
                ...state,
                customer: action.payload
            }
        }
        case 'GET_ORDERITEMS': {
            return {
                ...state,
                orderItems: action.payload
            }
        }
        case 'GET_DASHBOARD': {
            return {
                ...state,
                dashboard: action.payload
            }
        }
        case 'GET_PRODUCT': {
            return {
                ...state,
                product: action.payload
            }
        }
        default: {
            return state
        }
    }

}

export default Mainreducer;