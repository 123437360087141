import Avatar1 from '../../../assets/images/xs/avatar1.svg';
import Avatar2 from '../../../assets/images/xs/avatar2.svg';
import Avatar3 from '../../../assets/images/xs/avatar3.svg';
import Avatar4 from '../../../assets/images/xs/avatar4.svg';
import Avatar5 from '../../../assets/images/xs/avatar5.svg';
export const EmailinvoiceData = [
    {
        name: 'Rado Watch',
        price: '$ 330.00'
    },
    {
        name: '1 Year Product Warranty',
        price: '$ 10.99'
    },
    {
        name: 'Production Transportation',
        price: '$ 49.00'
    },
]
 
export const InvoiceListData = [
    {
      image:Avatar1,
      fname:'Ryan MacLeod',
      name:'Ke Arrow Silicon',
      address:'2211 Jones Avenue,Winston Salem FL 27107',
      money:'$500',
      date:'23 Feb, 2021',

    },
    {
        image:Avatar2,
        fname:'Penelope Stewart',
        name:'Kronos Minimalist',
        address:'3154  Sampson Street,Aurora CT 80014',
        money:' $433',
        date:'14 Apr, 2021',
  
      },
      {
        image:Avatar3,
        fname:'Diane	Slater',
        name:'Mechanical Watch',
        address:'49 Stamford Road. West Chicago, IL 60185',
        money:' $255',
        date:'16 Mar, 2021',
  
      },
      {
        image:Avatar4,
        fname:'Amy Mills',
        name:'Engraved Black Alloy',
        address:'2698  Northumberland. Melbourne, FL 32904',
        money:'$555',
        date:'17 Mar, 2021',
  
      },
      {
        image:Avatar5,
        fname:'Tim Mitchell',
        name:'Titan Workwear',
        address:'35 Gubener Str. Emmering, FL 32904',
        money:'$288',
        date:'12 Feb, 2021',
  
      },
      {
        image:Avatar2,
        fname:'Brian Swader',
        name:'NoiseFit Endure',
        address:'70 Bowman St. South Windsor, CT 06074',
        money:'$655',
        date:'18 Feb, 2020',
  
      },
]
export const SalaryslipAddressData = [
  {
      title: 'from',
      name: 'ebazar',
      address: '111  Berkeley Rd',
      add: 'STREET ON THE FOSSE, Poland',
      email: 'Email: info@deoweb.com',
      number: 'Phone: +44 888 666 3333'
  }, {
      title: 'To:',
      name: 'Dylan Hunter',
      address: 'Digital Marketing',
      add: 'Employee ID: 00008',
      email: 'Joining Date: 15 Feb 2017',
      number: 'Phone: +66 243 456 789'
  }
]

export const SalaryslipTable1Data = [
  {
      number: '1',
      title: 'Basic Salary',
      price: '$8000,00'
  },
  {
      number: '2',
      title: 'House Rent Allowance (H.R.A.)',
      price: '$50,00'
  },
  {
      number: '3',
      title: 'Conveyance',
      price: '$50,00'
  },
  {
      number: '4',
      title: 'Other Allowance',
      price: '$50,00'
  },
]

export const SalaryslipTable2Data = [
  {
      number: '1',
      title: 'Tax Deducted at Source (T.D.S.)',
      price: '$15,00'
  },
  {
      number: '2',
      title: 'Provident Fund',
      price: '$200,00'
  },
  {
      number: '3',
      title: 'ESI',
      price: '$0,00'
  },
  {
      number: '4',
      title: 'Other Deductions',
      price: '$0,00'
  },
]