import React from 'react';
import { Link } from 'react-router-dom';


function SignIn() {
    return (
        <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100 " >
            <div className="w-100 p-3 p-md-5 card border-0 shadow-sm" style={{ maxwidth: "32rem" }}>
                <form className="row g-1 p-3 p-md-4 mt-5">
                    <div className="col-12 text-center mb-0">
                        <h1>Sign  in</h1>
                    </div>
                    <div className="col-12">
                        <div className="mb-2">
                            <label className="form-label">Email address</label>
                            <input type="email" className="form-control form-control-lg lift" placeholder="name@example.com" />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-2">
                            <div className="form-label">
                                <span className="d-flex justify-content-between align-items-center">
                                    Password
                                    <Link className="text-secondary" to={"/reset-password"}>Forgot Password?</Link>
                                </span>
                            </div>
                            <input type="password" className="form-control form-control-lg lift" placeholder="***************" />
                        </div>
                    </div>
                    <div className="col-12 text-center mt-4">
                        <Link type='button' to={"/dashboard"} className="btn btn-lg btn-block btn-light lift text-uppercase" >SIGN IN</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default SignIn;