import React from "react";
import BadgesTile from "../../components/Uicomponent/BadgesTile";


function Badges (){
    return(
        <div className="container">
            <BadgesTile />
        </div>
    )
  }
export default Badges;