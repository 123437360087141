import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProducts } from "../../Redux/Actions/Action";
import axios from "axios";

const baseURL = "https://mitserver.app:1515";
const imgURL = "https://poly-lite.com/images/product-catalog/";

function ProductEdit() {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [row, setRow] = useState({});
	const [categories, setCategories] = useState([]);
	const [imageFile, setImageFile] = useState(null);
	const [previewImageUrl, setPreviewImageUrl] = useState(null);

	useEffect(() => {
		const row = location.state;
		setRow(row);
	}, [location]);

	useEffect(() => {
		getCategories();
	}, []);

	async function getCategories() {
		const response = await axios.post(baseURL + "/getLists", {
			list: "categories",
		});
		const data = response.data.result.data;
		setCategories(data);
	}

	async function updateProduct() {
		const formData = new FormData();
		formData.append("action", "update");
		formData.append("table", "products");
		formData.append("data", JSON.stringify(row));
		if (imageFile) {
			formData.append("file", imageFile);
		}

		const response = await axios.post(baseURL + "/actions", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		const result = response.data.result;
		dispatch(getProducts);
		toast.success(result.text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
		history.push("/product-list");
	}

	const handleChange = (e) => {
		const { name, value } = e.target;
		setRow((prevRow) => ({ ...prevRow, [name]: value }));
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		console.log("FILE", file);
		setImageFile(file); // Actualizar el estado imageFile

		// Crear una URL temporal para la imagen
		const imageUrl = URL.createObjectURL(file);

		// Actualizar el estado previewImageUrl con la URL temporal
		setPreviewImageUrl(imageUrl);
	};

	return (
		<div className="container-xxl">
			<div className="row align-items-center">
				<div className="border-0 mb-4">
					<div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
						<h3 className="fw-bold mb-0">Product Edit</h3>
						<button type="submit" className="btn btn-primary btn-set-task w-sm-100 text-uppercase px-5" onClick={updateProduct}>
							Save
						</button>
					</div>
				</div>
			</div>
			<div className="row g-3">
				<div className="col-xl-4 col-lg-4">
					<div className="sticky-lg-top">
						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Pricing Info</h6>
							</div>
							<div className="card-body">
								<div className="row g-3 align-items-center">
									<div className="col-md-12">
										<label className="form-label">Product Price</label>
										<input type="text" className="form-control" name="price" value={row.price} onChange={handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Inventory Info</h6>
							</div>
							<div className="card-body">
								<div className="row g-3 align-items-center">
									<div className="col-md-12">
										<label className="form-label">SKU</label>
										<input type="text" className="form-control" name="code" value={row.code} onChange={handleChange} />
									</div>
									<div className="col-md-12">
										<label className="form-label">Total Stock Quantity</label>
										<input type="text" className="form-control" name="stock" value={row.stock} onChange={handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Visibility Status</h6>
							</div>
							<div className="card-body">
								<div className="form-check">
									<input
										className="form-check-input"
										type="radio"
										name="status"
										value="Enable"
										checked={row.status === "Enable"}
										onChange={handleChange}
									/>
									<label className="form-check-label">Enable</label>
								</div>
								<div className="form-check">
									<input
										className="form-check-input"
										type="radio"
										name="status"
										value="Disable"
										checked={row.status === "Disable"}
										onChange={handleChange}
									/>
									<label className="form-check-label">Disable</label>
								</div>
							</div>
						</div>

						<div className="card mb-3">
							<div className="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom-0"></div>
							<div className="card-body">
								<h6 className="mb-2 fw-bold">Unit</h6>
								<div className="col-md-12">
									<input type="text" className="form-control" name="unit" value={row.unit} onChange={handleChange} />
								</div>
								<h6 className="mt-2 fw-bold">Weight</h6>
								<div className="col-md-12">
									<input type="text" className="form-control" name="weight" value={row.weight} onChange={handleChange} />
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
								<h6 className="m-0 fw-bold">Categories</h6>
							</div>
							<div className="card-body">
								<label className="form-label">Categories Select</label>
								<select
									className="form-select"
									size="3"
									aria-label="size 4 select example"
									name="category"
									value={row.category}
									onChange={handleChange}
								>
									{categories.map((c) => (
										<option key={c.id} value={c.id}>
											{c.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-8 col-lg-8">
					<div className="card mb-3">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Basic information</h6>
						</div>
						<div className="card-body">
							<form>
								<div className="row g-3 align-items-center">
									<div className="col-md-6">
										<label className="form-label">Name</label>
										<input type="text" className="form-control" name="name" value={row.name} onChange={handleChange} />
									</div>

									<div className="col-md-12">
										<label className="form-label">Product Description</label>
										<textarea className="form-control" name="description" value={row.description} onChange={handleChange} />
									</div>
								</div>
							</form>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
							<h6 className="mb-0 fw-bold ">Images</h6>
						</div>
						<div className="card-body">
							<div className="row g-3 align-items-center">
								<div className="col-md-12">
									<label className="form-label">Product Images Upload</label>
									<div id="create-token" className="dropzone">
										{previewImageUrl ? (
											<img src={previewImageUrl} alt="" />
										) : (
											<div className="dz-message ">
												<i className="fa fa-picture-o" aria-hidden="true"></i>
											</div>
										)}
										<input
											id="filesize"
											name="image"
											type="file"
											accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .mp4, .webm, .mp3, awv, .ogg, .glb"
											onChange={handleImageChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProductEdit;
