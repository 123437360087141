import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from "axios";
import { getCategories } from '../../Redux/Actions/Action';
// import { useDispatch } from 'react-redux';
const baseURL = "https://mitserver.app:1515";

function CategoriesList() {

    const dispatch = useDispatch();
    const history = useHistory();
    const categories = useSelector((state) => state.Mainreducer.categories);

    useEffect(() => {
        if (categories.length === 0) {
            console.log("EMPTY Categories");
            dispatch(getCategories);
        }
    }, []);

    async function deleteCustomer(selected) {
        const response = await axios.post(baseURL + "/actions", {
            action: 'delete',
            table: 'categories',
            data: selected
        })
        var data = response.data;
        var result = data.result;
        console.log("RESP", result);
        dispatch(getCategories);
        toast.success(result.text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    const editButton = (row) => {
        history.push({
            pathname: "/categories-edit",
            state: row
        });
    }

    return (
        <div className="body d-flex py-3">
            <div className="container-xxl">
                <div className="row align-items-center">
                    <div className="border-0 mb-4">
                        <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                            <h3 className="fw-bold mb-0">Categories List</h3>
                            <Link to={'categories-add'} className="btn btn-primary btn-set-task w-sm-100"><i className="icofont-plus-circle me-2 fs-6"></i>Add Category</Link>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="myDataTable_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className='table-responsive'>
                                                <table id="myDataTable" className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline" style={{ width: '100%' }} role="grid" aria-describedby="myDataTable_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th className="sorting_asc" tabIndex="0" aria-controls="myDataTable" rowSpan="1" colSpan="1" style={{ width: '32.2px' }} aria-label="Id: activate to sort column descending" aria-sort="ascending">Id</th>
                                                            <th className="sorting" tabIndex="0" aria-controls="myDataTable" rowSpan="1" colSpan="1" style={{ width: '117.2px' }} aria-label="Categorie: activate to sort column ascending">Category</th>
                                                            <th className="dt-body-right sorting" tabIndex="0" aria-controls="myDataTable" rowSpan="1" colSpan="1" style={{ width: '118.2px' }} aria-label="Date: activate to sort column ascending">Code</th>
                                                            <th className="sorting" tabIndex="0" aria-controls="myDataTable" rowSpan="1" colSpan="1" style={{ width: '59.2px' }} aria-label="Status: activate to sort column ascending">Status</th>
                                                            <th className="dt-body-right sorting" tabIndex="0" aria-controls="myDataTable" rowSpan="1" colSpan="1" style={{ width: '75.2px' }} aria-label="Action: activate to sort column ascending">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categories.length > 0 && (categories.map((c) => (
                                                            <tr key={c.id} role="row" className="odd">
                                                                <td className="sorting_1" tabIndex="0">{c.id}</td>
                                                                <td>{c.name}</td>
                                                                <td className=" dt-body-right">{c.code}</td>
                                                                <td><span className={`badge ${c.status === "Enable" ? 'bg-success' : 'bg-danger'}`}>{c.status}</span></td>
                                                                <td className=" dt-body-right">
                                                                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                                        <button onClick={() => editButton(c)} type="button" className="btn btn-outline-secondary"><i className="icofont-edit text-success"></i></button>
                                                                        <button onClick={() => { deleteCustomer(c) }} type="button" className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CategoriesList;